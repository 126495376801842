// Here you can add other styles
.card,
.dropdown-menu,
.header,
.sidebar {
  box-shadow: rgb(50 50 93 / 2%) 0 2px 5px -1px, rgb(0 0 0 / 5%) 0 1px 3px -1px;
}

.sidebar-overlaid {
  box-shadow: rgb(50 50 93 / 2%) 0 2px 5px -1px, rgb(0 0 0 / 5%) 0 1px 3px -1px, 0 5px 6px -3px rgb(0 0 0 / 25%);
}

.card-header {
  border-bottom: 1px solid var(--cui-card-border-color);
}

.header {
  border: 0;
}

.userImageHeader {
  height: 40px; /* hauteur du conteneur */
  width: 40px; /* largeur du conteneur */
  overflow: hidden; /* masque tout contenu dépassant du conteneur */
  border-radius: 50%; /* rend l'image circulaire */
}

/*Managing img styling all across the app*/
/*user avatar - img in modal-body  - img in card-body */
.userImageHeader img, .modal-body img{
  height: auto; /* fit the container height */
  width: 100%; /* adjust the width to preserve the ratio */
}
/*Managing img styling all across the app*/


/*Managing thumb-slide style across the app*/
.thumb-slide section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px; /* Ensure the card body into the timeline is 250px */
  width: 100%; /* Ensure the card body takes the full width of its container */
  padding: 1rem; /* Add some padding if needed */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.thumb-slide section {
  max-width: 100%;
  max-height: 100%;
}

.thumb-slide section img {
  max-height: 80%; /* fit the container height */
  width: auto; /* adjust the width to preserve the ratio */
  max-width: 100%;
}

.thumb-slide section iframe {
  height: 100%; /* fit the container height */
  width: auto; /* adjust the width to preserve the ratio */
  max-width: 100%;
}

.thumb-slide section p {
  white-space: normal;
}


.thumb-slide section h1 {
  font-size:18px;
  text-wrap: wrap;
}

.thumb-slide section h2 {
  font-size:16px;
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: center;
  align-items: center;
}
/*Managing thumb-slide style across the app*/


.sidebar-nav {
  //margin-bottom: .75rem;

  .nav-link {
    @include border-radius($border-radius);
  }

  .nav-group.show .nav-group-toggle:hover {
    color: $high-emphasis-inverse;
  }
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
  --cui-sidebar-nav-link-disabled-color: initial;
  color: rgb(255, 255, 255)
}
.sidebar-brand {
  justify-content: space-between;
  padding: 0 $sidebar-nav-link-padding-x;
}

.sidebar-toggler {
  flex: 0 0 2rem;
  background-color: transparent;

  &::before {
    width: 2rem;
  }

  &:hover {
    background-color: transparent;
  }
}

.sidebar-narrow-unfoldable:not(:hover) .sidebar-toggler {
  display: none;
}


//Sidebar is dark
.sidebar-dark {
  --cui-sidebar-bg: #001481;
}

//the margin between an icon and a text is equal to 20px
.nav-link .svg-inline--fa {
  margin-right: 25px;
}

.open-item {
  border-left-width: 6px;
  border-left-color: $info;
}

.closed-item {
  border-left-width:6px;
  border-left-color: $primary;
  text-decoration: line-through;
}
.archived-item {
  border-left-width:6px;
  border-left-color: $dark;
  text-decoration: line-through;
}

.bg-modify {
  background-color:$modify;
  border-color:$modify;
}

.btn.disabled {
  color: $gray-100;
}


.success-background {
  background-color: green;
  color: white;
}

.success-background .btn-close {
  color: white;
}


.bg-gradient-blue {
  --bs-bg-opacity: 1;
  background-image: linear-gradient(90deg,#001481,#0484c4);
}


.container-lg{
  max-width: 1980px;
}

.icon-list {
  font-family: inherit, sans-serif, fontAwesome, serif;
}


.checkbow100 label{
  width:100%;
}


@media (min-width: 1400px){
  .noddleBody{
    height: 225px
  }
  }

@media (min-width: 1200px) and (max-width: 1400px){
  .noddleBody{
    height: 175px
  }
  }

@media (min-width: 991px) and (max-width: 1200px){
  .noddleBody{
    height:150px
  }
  }

@media (max-width: 991px) and (min-width: 567px) {
  .noddleBody{
    height:225px
  }
  }

  @media (max-width: 566px){
    .noddleBody{
      height:225px
    }
    }

.noddle_wrapper{
  margin-top: -8%;
}
.noddle_additional{
  background-color: $gray-300;
}
.dpinfo {
  overflow:hidden;
  width:94%;
  vertical-align:middle;
}
.accordion{
  color:red !important;
}

.sidebar-narrow-unfoldable:not(:hover) .sidebar-brand-full {
  display: block;
}
.sidebar-narrow .sidebar-brand-full {
  display: none;
}

.sidebar-brand {
  justify-content: space-between;
  padding: 0 1rem;
}

.node__root > circle {
  fill: $primary;
}
.node__branch > circle {
  fill: $info;
}

.lean-training-title:after {
  content: "";
  display: block;
  background-color: #ed1c24;
  width: 50px;
  height: 3px;
  margin-top: 5px;
  transition-duration: .3s;
}

.sidebar-nav  >li:not(.bottom) + .bottom {
  margin-top: auto;
}

// DATEPICKER
.board_container .date-picker {
  min-width: 180px;
};

.date-picker-input {
  font-size: 1rem !important;
  cursor: pointer;

};


// FullCalendar custom **************************
.fc-day:hover{
  background:lightblue;
  cursor:pointer
}
.fc-day-today {
  background: #000f83 !important;
}
.fc-day-today a{
color: white;
}
.fc-event-main {
  height: 75px;
  font-size: 25px;
  text-align: center;
  margin-top: 40px;
}
.fc-daygrid-day-top{
  margin: 10px 0px;
}
.fc-col-header-hidden .fc-col-header thead {
  display: none;
}





/*Allow pointer-events through*/
.fc-slats, /*horizontals*/
.fc-content-skeleton, /*day numbers*/
.fc-bgevent-skeleton /*events container*/{
  pointer-events:none
}

/*Turn pointer events back on*/
.fc-bgevent,
.fc-event-container{
  pointer-events:auto; /*events*/
}

.fc-event-title-container{
  text-align: center;
}
.fc-event-title.fc-sticky{
font-size: 1rem;
}


@media (max-width: 600px) {
  .card-body {
    flex-direction: column;
  }
}

//Accordion disabled on PDCA
.disabled-accordion {
  pointer-events: none;
  opacity: 0.5;
}

.disabled-button {
  pointer-events: none;
  opacity: 0.3;
}

.disabled-multiselect{
  pointer-events: none;
  opacity: 1;
}

//toaster
.fixed-toaster {
  position: fixed;
  top: 8%;
  right: 2%;
  z-index: 9999;
}

.form-multi-select-tag-delete{
  display: none;
}

// TABLES

.table-responsive table {
  column-width: auto !important;
}

.clicked-row {
 // background-color: yellow !important; /* Change this to whatever color you want */
  color: $success;

}

//fileViewer modal
.fileViewerModal .btn-close {

}

//modal
.modal-header .btn-close {
  margin: 0;
}

.grabbing {
  cursor: grabbing !important;
}

.preview:hover {
  cursor: pointer;
  border: 1px solid $primary !important;
}
.preview-active {
  cursor: pointer;
  border: 1px solid $primary !important;
  opacity: 0.5;
}

//progress-bar
/*progressbar*/
#progressbar {
  margin-bottom: 5px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  color: $primary;
  text-transform: uppercase;
  font-size: 12px;
  width: 50%;
  float: left;
  position: relative;
  letter-spacing: 1px;
  text-align: center;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: block;
  font-size: 12px;
  color: $primary;
  border: 1px solid $primary;
  background: white;
  border-radius: 25px;
  margin: 0 auto 10px auto;
}

/*progressbar connectors*/
#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: black;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1; /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before, #progressbar li.active:after {
  background: $primary;
  color: white;
}


/*slideshow module*/

.slide-header-1, .slide-header-2, .slide-header-3 {
  fill: #28a745;
}

.slide-header-1, .slide-header-4, .slide-header-5 {
  stroke-width: 0px;
}

.slide-header-6, .slide-header-2, .slide-header-7, .slide-header-8, .slide-header-3, .slide-header-9, .slide-header-10 {
  isolation: isolate;
}

.slide-header-6, .slide-header-2, .slide-header-7, .slide-header-11, .slide-header-3, .slide-header-9, .slide-header-10 {
  font-size: 48px;
}

.slide-header-6, .slide-header-2, .slide-header-10 {
  font-family: Roboto-Regular, Roboto;
}

.slide-header-6, .slide-header-4, .slide-header-9 {
  fill: #eaa654;
}

.slide-header-7, .slide-header-11, .slide-header-3, .slide-header-9 {
  font-family: Roboto-Medium, Roboto;
  font-weight: 500;
}

.slide-header-7, .slide-header-11, .slide-header-5, .slide-header-10 {
  fill: #001481;
}

.slide-header-12 {
  letter-spacing: .02em;
}

.slide-header-13 {
  letter-spacing: -.02em;
}

.slide-header-14 {
  fill: none;
  stroke: #001481;
  stroke-miterlimit: 10;
}


.slide-container {
  width: 100%;
  height: fit-content;
}

.slide-h1 {
  font-size: 1em; /* This makes the font size 2 times the font size of the parent element */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  text-align: center; /* Center align the text */
}

.slide-h2 {
  font-size: 0.5em; /* This makes the font size 2 times the font size of the parent element */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  text-align: center; /* Center align the text */
}

#slide-date {
  font-size: 0.5em; /* This makes the font size 2 times the font size of the parent element */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  text-align: left; /* Center align the text */
}


#slide-logo {
  text-align:right;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

.label {
  color: #001481;
  position:relative;
  top:-20px;

}

.right-logo {
  float:right;
  text-align:right;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  height:80px;
  background: transparent;
}

.slide-bg{
  fill:#FFFFFF;
}
.slide-title {
  fill: #001481;
  font-family: inherit, sans-serif;
  font-size: 40px;
  font-weight: 700;
}

.slide-subtitle,.slide-text {
  fill: #07BFBB;
  font-family: inherit, sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.slide-service {
  fill: #001481;
  font-family: inherit, sans-serif;
  font-size: 30px;
  font-weight: 700;
  text-decoration: underline;
}

.slide-date-rect {
  fill: none;
  stroke: #001481;
  stroke-miterlimit: 10;
}

.slide-date-text {
  fill: #001481;
  font-family: inherit, sans-serif;
  font-size: 48px;
  font-weight: 700;
}

.slide-date-ic {
  fill: #001481;
  stroke-width: 0px;
}

.slide-date-day1 {
  fill: #001481;
  font-size: 79.9px;
  font-weight: 700;
}

.slide-date-day2 {
  font-size: 40px;
  fill: #139f45;
  font-weight: 700;
}

.nqc-result {
  font-size: 36px;
  fill:#FB4143;
  font-weight: 700;
}

.slide-goal {
  color:#68EDED;
  font-size: 30px;
  font-weight: 700;
}

.slide-goal-ic {
  color:#68EDED;
}

.slide-goal-txt {
  color:#68EDED;
  font-size: 21px;
}


.slide-alert {
  color: #FFB8B8;
  font-size: 30px;
  font-weight: 700;
}

.slide-alert-ic {
  color: #FFB8B8;
}

.slide-alert-txt {
  color: #FFB8B8;
  font-size: 21px;
}

.slide-result {
  color: #001481;
  font-size: 24px;
  font-weight: 700;
}

.slide-result-ic {
  color: #001481;
}

.slide-result-txt {
  color: #001481;
  font-size: 21px;
}

.slide-light-text {
  font-size: 20px;
  font-weight: 500;
}

.slide-light-text-no-result {
  font-size: 20px;
  font-weight: 500;
  color: #ec0d0d;
}

.slide-small-text {
  font-size: 18px;
}

.goal-achievement {
  font-size: 16px;
  font-style:italic;
  font-weight: 700;
  color: #28a745;
}

.goal-achievement-text {
  font-size: 16px;
  font-weight: 700;
  color: #28a745;
}


/*title slide*/
.stti0{fill:#FFFFFF;}
.stti1{fill:#07BFBB;}
.stti2{  font-family: inherit, sans-serif;}
.stti3{font-size:40px;}
.stti4{fill:#70706F;}
.stti5{font-size:30px;}

/*title & content*/
.sttc0{fill:#FFFFFF;background-color: white;min-height:105px;}
.sttc1{fill:#07BFBB;}
.sttc2{font-family: inherit, sans-serif;}
.sttc3{font-size:80px;}
.sttc4{fill:none;}
.sttc4-desc {text-align: center;font-family: inherit, sans-serif;color:#70706F;}

.container-thumbnail div {
  //max-width: 1000px; /* Set the width of the container */
  //max-height: 150px; /* Set the height of the container */
  display: flex; /* Use flexbox */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.container-media div {
  //max-width: 568px;
  //max-height: 568px;
  display: flex; /* Use flexbox */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  margin:auto;
}
.video {
  width: 100%; /* Set video width to 100% of container width */
  height: 100%; /* Set video height to 100% of container height */
  object-fit: cover; /* Ensure the video maintains aspect ratio and covers the container */
}

.audio {
  width: 100%; /* Set video width to 100% of container width */
  height: 100%; /* Set video height to 100% of container height */
  object-fit: cover; /* Ensure the video maintains aspect ratio and covers the container */
}

.link {
  cursor: pointer;
  width: 100%; /* Set video width to 100% of container width */
  height: 100%; /* Set video height to 100% of container height */
  object-fit: cover; /* Ensure the video maintains aspect ratio and covers the container */
}


.sttc5{fill:#70706F;}
.sttc6{font-size:36px;}

/*pie chart*/
.stpc0{fill:#FFFFFF;}
.stpc1{fill:#001481;}
.stpc2{display:none;fill:#5BBC5B;}
.stpc3{fill:#5BBC5B;}
.stpc4{enable-background:new    ;}
.stpc5{font-family: inherit, sans-serif;font-weight:bold}
.stpc6{font-size:16px;}
.stpc7{font-family: inherit, sans-serif;}
.stpc8{font-size:16px;}
.stpc9{font-size:21px;}
.stpc10{fill:#07BFBB;}
.stpc11{font-size:30px;}
.stpc12{fill:none;}
.stpc13{font-size:24px;}

/*bar chart*/
.cls-1 {
  fill: #0a62a5;
}

.cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7 {
  stroke-width: 0px;
}

.cls-8 {
  fill: #70706f;
  font-family: OpenSans, 'Open Sans';
  font-size: 15.4px;
}

.cls-8, .cls-9, .cls-10 {
  isolation: isolate;
}

.cls-2, .cls-11, .cls-12 {
  fill: none;
}

.cls-3 {
  fill: #e6e7e8;
}

.cls-4 {
  fill: #fb4143;
}

.cls-11 {
  stroke: green;
}

.cls-11, .cls-12 {
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.cls-5 {
  fill: green;
}

.cls-12 {
  stroke: #fb4143;
}

.cls-6 {
  fill: #bdbdb0;
}

.cls-10 {
  fill: #000;
  font-family: ArialMT, Arial;
  font-size: 21px;
}

.cls-7 {
  fill: #001481;
}

/*line chart*/

.goal-color {
  fill:#D0F9F9;
}

.alert-color {
  fill:#FFCCCC;
}
.result-color {
  fill: #001481;
}

.goal {
  color: green;
}

.chart-alert {
 color: #eaa654;
}

.result {
  color: #001481;
}

.green-result {
  fill: green;
}

.orange-result {
  fill: #FFC107;
}

.red-result {
  fill: red;
}

.stlc-1, .stlc-2, .stlc-3, .stlc-4, .stlc-5, .stlc-6 {
  stroke-width: 0px;
}

.stlc-2, .stlc-7, .stlc-8, .stlc-9 {
  fill: none;
}

.stlc-7 {
  stroke: #0a62a5;
}

.stlc-7, .stlc-8, .stlc-9 {
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.stlc-3 {
  fill: #e6e7e8;
}

.stlc-4 {
  fill: #eaa654;
}

.stlc-8 {
  stroke: green;
}

.stlc-10, .stlc-11 {
  fill: #70706f;
  font-family: OpenSans, 'Open Sans';
  font-size: 15.4px;
}

.stlc-5 {
  fill: green;
}

.stlc-9 {
  stroke: #fb4143;
}

.stlc-11, .stlc-12 {
  isolation: isolate;
}

.stlc-6 {
  fill: #bdbdb0;
}

.stlc-12 {
  fill: #000;
  font-family: ArialMT, Arial;
  font-size: 21px;
}
/*safety cross*/
.slides #safety-cross,.slides #nqc {
  width:50%;
}

.sc-1 {
  font-size: 30px;
}

.sc-1, .sc-2, .sc-3 {
  isolation: isolate;
}

.sc-1, .sc-3 {
  font-family: Roboto-Medium, Roboto;
  font-weight: 500;
}

.sc-1, .sc-4 {
  fill: #139f45;
}

.sc-3 {
  fill: #000;
  font-size: 52.59px;
}

.sc-4, .sc-5, .sc-6, .sc-7 {
  stroke-width: 0px;
}

.sc-5 {
  fill: #9ac43e;
}

.sc-6 {
  fill: url(#Dégradé_sans_nom);
}

.sc-7 {
  fill: #fff;
}
/*slideshow display*/
.reveal .back2app {
  display: none;
}
.reveal.overview .back2app {
    display: block;
}

/*user profile page*/
.userProfileImage {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50% ;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #001481;
  color: #FFF;
  border-radius: 4px;
}

/*account page*/
.accountLogo {
  width: 300px;
  height: 300px;
  object-fit: cover;
}


/*Dashboard page*/
.cardDiagrame{
  overflow-x: hidden;
}

.cardDiagrame::-webkit-scrollbar {
  width: 5px;
}

.cardDiagrame::-webkit-scrollbar-thumb {
  background-color: #6c6c6c;
  border-radius: 5px;
}
@media (max-width: 992px) and (min-width: 350px) {
  .cardDiagrame{
    height: 400px;
  }
}

@media (min-width: 993px) {
  .cardDiagrame{
    height: 500px;
  }
}


/*Login page*/

.loginBody{
  overflow-x: hidden;
  overflow-y: hidden;
}

.loginColumnsLeft{
  background-color: #001481;
  height:100vh
}

.loginColumnsRight{
  height: 100vh;
}

@media (max-width: 991px) {
.loginColumnsRight{
  background-color: #001481 !important;

  height:110vh;
}
}




/*Saisie rapide*/
.custom-modal .modal-dialog {
  max-width: 95%; /* Changer la largeur personnalisée selon vos besoins */
}
/*hori timeline*/

/*horizontal scrollbar*/
.hori-timeline {
  overflow-x: auto;
  white-space: nowrap;
}

.hori-timeline .events {
  border-top: 3px solid #e9ecef;
 background-color: #E6E8F2;
  border-radius: 5px;
  overflow-x: auto;
  white-space: nowrap;
}

.hori-timeline .events .event-list {
  display: block;
  position: relative;
  text-align: center;
  padding-top: 35px;
  margin-right: 0;
}
.hori-timeline .events .event-list:before {
  content: "";
  position: absolute;
  height: 36px;
  border-right: 2px dashed #dee2e6;
  top: 0;
}
.hori-timeline .events .event-list .event-date {
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
  width: 75px;
  margin: 0 auto;
  border-radius: 4px;
  padding: 2px 4px;
}
@media (min-width: 1140px) {


  .hori-timeline .events .event-list {
    display: inline-block;
    width: 20%;
    padding-top: 35px;
  }
  .hori-timeline .events .event-list .event-date {
    top: -12px;
  }
}

/*DnD*/

.Board {
  border: 1px solid black;
}

/*Blocks*/
.scroll-block {
  height: 400px;
  overflow-y: scroll;
}

.input-block {

  display: inline-block;
  max-width: 70px;
  height: 38px;
  padding:0px;
}

/*object html tag*/

.object {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  aspect-ratio: 4 / 3;
}

.slideContent {
  height:325px;
}

.slideContent2 {
height:fit-content;
}




.PDCACard::-webkit-scrollbar {
  width: 5px;
}

.PDCACard::-webkit-scrollbar-thumb {
  background-color: #001481;
  border-radius: 5px;
}




@mixin random-bgr(){
  background-color: rgb(random(255), random(255), random(255));
}

/*Theme dark*/


$dark-light: #6c6c6c;
$dark-normal: #4c4c4c;
$dark-dark: #313131;

$text-white:#fff ;
$text-dark: #ccc;
$text-dark-light:#d6d6d6;
$text-dark-red:#d62e3f;
$text-dark-yellow:#bb9c3f;
$text-dark-light-cyan:#c3ebea;

$dark-page-link :#545151;
$dark-page-item: #929292;

$link-dark : #b1b1b1;

$dark-light-active: #7f7f7f ;
$dark-cyan: #37BFBB ;
$dark-red: #c7424f;
$dark-green: #3f9854;

$dark-red-hover:#c93441;

$btn-color-dark:#8c8c8c;
$btn-color-dark-dark:#3a3a3a;
$btn-color-dark-hover:#807c7c;
$btn-dark-lean-hover: #3e3e3e;
$btn-dark-green: #3a7948;
$btn-dark-green-hover:#368247;
$btn-dark-yellow-hover: #3c3c3c;
$btn-dark-yellow: #caa843;

$btn-list-dark:#a5a5a5;

$dark-table-border:#818181;

$dark-calendar-cell-disabled: #626262;
$dark-calendar-header : #3b3b3b;




.event_content{
  position: absolute;
  top: -40px;
  right: 0;
}

.theme_dark_light{
  background-color: $dark-light;
  color: $text-dark;
  border:none;
}

.theme_dark_light:hover{
  background-color: $dark-light;
}

.theme_dark_light:active{
  background-color: $dark-light-active !important;
}


.theme_slide_dark_light{
  background-color: $dark-light;
  color: $text-dark;
  border:none;
}

.theme_dark_normal{
  background-color: $dark-normal !important;
  color: $text-dark !important;
  border:none;
}

.theme_dark_normal .btn-close{
  background-color: $text-dark;
  color: $text-dark;
}

.theme_dark_normal .rct-icon{
  color: $text-dark;
}

.theme_dark_normal .form-check-input:checked {
  color: $text-dark !important;
  background-color: $dark-cyan !important;
  border-color: $dark-cyan !important;
}

.theme_dark_normal .form-control,
.theme_dark_normal .form-select,
.theme_dark_normal .form-multi-select,
.theme_dark_normal .form-multi-select-tag,
.theme_dark_normal .form-multi-select-search,
.theme_dark_normal .form-multi-select-options,
.theme_dark_normal .form-control::placeholder,
.theme_dark_normal .form-select::placeholder,
.theme_dark_normal .form-multi-select::placeholder
{
  color: $text-dark !important;
  background-color: $dark-dark !important;
  border-color: $dark-normal !important;
}

.theme_dark_normal .form-range{
  background-color: $dark-normal !important;
  color: $text-dark !important;
  border:none;
}

.theme_dark_normal input{
  color: $text-dark !important;
  background-color: $dark-dark ;
  border-color: $dark-normal !important;
}


.theme_dark_normal .form-control:disabled,
.theme_dark_normal .form-select:disabled,
.theme_dark_normal .form-multi-select:disabled,
.theme_dark_normal .form-multi-select-tag:disabled,
.theme_dark_normal .form-multi-select-search:disabled,
.theme_dark_normal .form-multi-select-options:disabled,
.theme_dark_normal .form-multi-select.disabled,
.theme_dark_normal textarea:disabled::placeholder,
.theme_dark_normal .form-control:disabled::placeholder
{
  color: $text-dark !important;
  background-color: $dark-light !important;
  border-color: $dark-normal !important;
}

.theme_dark_normal .form-multi-select-dropdown{
  border-color: $dark-light;
}

.theme_dark_normal .form-multi-select-option.form-multi-selected::before,
.theme_dark_normal .form-multi-select-option.form-multi-select-option-with-checkbox::before{
  background-color: $dark-dark !important;
  border-color: $dark-dark;
}

.theme_dark_normal .calendar-header-cell-inner,
.theme_dark_normal .calendar-cell-inner,
.theme_dark_normal .input-group-text,
.theme_dark_normal .dropdown-menu{
  color: $text-dark !important;
  background-color: $dark-dark !important;
  border-color: $dark-normal !important;
}

.theme_dark_normal .calendar-cell.disabled .calendar-cell-inner{
  color: $dark-calendar-cell-disabled !important;
}

.theme_dark_normal .calendar-cell-inner:active,
.theme_dark_normal .calendar-cell-inner:hover{
  color: $dark-dark !important;
  background-color: $dark-light !important;
}

.theme_dark_normal .calendar-nav ,
.theme_dark_normal .calendar-nav .btn span,
.theme_dark_normal .calendar-nav .btn .btn-transparent .calendar-nav-icon-double-next,
.theme_dark_normal .picker-footer

{
  color: $dark-normal !important;
  background-color: $text-dark !important;
  border-color: $dark-normal !important;
}

.theme_dark_normal .picker-footer button{
  color: $text-dark !important;
  background-color: $dark-dark !important;
  border-color: $dark-normal !important;
}


.theme_dark_normal .form-multi-select-option{
  color: $text-dark !important;
  background-color: $dark-light !important;
  border-color: $dark-normal !important;
  margin: 5px 0px;
}

.theme_dark_normal .form-multi-select button{

  color: $text-dark !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $dark-dark !important; /* couleur de fond de l'input lorsqu'il est en focus ou actif */
  border-color: $dark-dark;
}


.theme_dark_normal .pagination .page-item:disabled a,
.theme_dark_normal .pagination .page-item a,
.theme_board_dark_normal .pagination .page-item:disabled a,
.theme_board_dark_normal .pagination .page-item a{
  background-color: $dark-light !important;
  color: $text-dark !important;
  border:1px solid $dark-light;
}

.theme_dark_normal .page-link,
.theme_board_dark_normal .page-link{
  background-color: $dark-page-link !important;
  color: $text-dark !important;
  border:1px solid $dark-page-link;
}


.theme_dark_dark{
  background-color: $dark-dark !important;
  color: $text-dark;
  border:none;
}

.theme_dark_dark .accordion-header .accordion-button{
  background-color: $dark-light;
  color: $text-dark;
  border:none;
  box-shadow: none;
}


.theme_dark_dark .dropdown-item:hover{
  background-color: $dark-normal;
  color: $text-dark;
  cursor: pointer;

}

.theme_dark_dark::placeholder{
  color: $text-dark;
}

.theme_dark_dark input:focus,
.theme_dark_dark input:active {
  color: $text-dark !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $dark-dark !important;
}

.theme_dark_dark:focus,
.theme_dark_dark:active {
  color: $text-dark !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $dark-dark !important; /* couleur de fond de l'input lorsqu'il est en focus ou actif */
}

.theme_navbar_dark{
  background-color: $dark-dark !important;
  color: $text-dark ;
}

.theme_lean_dark_dark{
  color: $text-dark;
  background-color: $dark-dark !important;
  border: $dark-dark solid 1px;
}

.theme_lean_dark_dark:hover{
  color: $text-dark;
  background-color: $btn-dark-lean-hover !important;
  border: $btn-dark-lean-hover solid 1px;
}


.theme_lean_dark_dark:active{
  color: $text-dark;
  background-color: $btn-dark-lean-hover !important;
  border: $btn-dark-lean-hover solid 1px !important;
}

.theme_slideshow_dark_dark{
  background-color: $dark-dark !important;
  padding-top : 10px;
  color: $text-dark;
  border:none;
}


.theme_slideshow_dark_normal{
  background-color: $dark-normal !important;
  padding-top : 10px;
  color: $text-dark;
  border:none;
}

 .hori-timeline .events{
  border-top:none;
}

.theme_dark_red{
  background-color: $dark-red;
  border: 1px solid $dark-red;
}

.theme_dark_green{
  background-color: $dark-green;
  border:none;
}


.theme_board_dark_normal{
  background-color: $dark-dark !important;
  color:$text-dark;
  border-color: $dark-dark !important;

}

.theme_board_dark_normal input,
.theme_board_dark_normal input::placeholder,
.theme_board_dark_normal input:focus,
.theme_board_dark_normal .form-select
{
  background-color: $dark-normal !important;
  color:$text-dark;
  border-color: $dark-light !important;

}

.theme_board_dark_light{
  background-color: $dark-light;
  color:$text-dark;
  border-color: $dark-light !important;
}

.theme_board_dark_dark{
  background-color: $dark-dark !important;
  color:$text-dark;
  border-color: $dark-dark !important;
}


.theme_dark_dark .page-item a,
.theme_dark_normal .page-item a{
  background-color: $dark-light !important;
  color:$text-dark;
  border-color: $dark-light !important;
}


.theme_dark_dark .page-item span,
.theme_dark_normal .page-item span{
  background-color: $dark-page-item !important;
  color:$text-dark;
  border-color: $dark-page-item !important;
}



.theme_text_noddle_dark{
  color: $text-dark !important;
}


.theme_calendar_dark_light,
.theme_calendar_dark_light button
{
  background-color: $dark-light !important;
  color: $text-dark !important;
  border:none;
  box-shadow: none !important;
}

.theme_calendar_dark_normal{
  background-color: $dark-normal;
  color: $text-dark;
  border:none;
}

.theme_calendar_dark_dark{
  background-color: $dark-dark;
  color: $text-dark;
  border:none;
}

.theme_calendar_dark_dark .fc-daygrid-day-number{
  color: $text-dark;
}

.theme_calendar_dark_dark .fc-button,
.theme_calendar_dark_dark .form-check-input:checked,
.theme_calendar_dark_dark .form-check-input::before
{
  background-color: $dark-cyan !important;
  border-color: $dark-cyan !important;
}

.theme_calendar_dark_dark .fc-day-today{
  background-color: $dark-normal !important;
}

.theme_calendar_dark_dark .fc-col-header-cell,
.theme_calendar_dark_dark .fc-col-header-cell a
{
  color: $text-dark;
  background-color: $dark-calendar-header !important;
  border-color: $dark-calendar-header !important;
}

.theme_calendar_dark_dark .fc-daygrid-day-frame:hover,
.theme_calendar_dark_dark .fc-daygrid-day-frame:active{
  background-color: $dark-light;
}

.theme_user_checkbox_dark{
  background-color: $dark-dark !important;
  border-color: $dark-dark !important;
}
















.theme_text_dark{
  color: $text-dark !important;
}

.theme_text_dark:disabled{
  color: $text-dark !important;
}

.theme_text_dark:hover{
  color: $text-dark-light;

}

.theme_text_dark:active{
  color: $text-dark;
}

.theme_text_red_dark{
  color: $text-dark-red !important;
}

.theme_text_green_dark{
  color: $dark-green !important;
}

.theme_text_yellow_dark{
  color: $text-dark-yellow !important;
}

.theme_text_cyan_dark{
  color: $text-dark-light-cyan !important;
}

.theme_home_text_dark{
  color: $text-dark;
}

.theme_text_light_dark{
  color: $text-dark-light !important;
}

.theme_link_dark{
  color: $link-dark !important;
}

.theme_link_dark a{
  color: $text-dark;
}

.theme_link_dark span{
  color: $text-dark;
}

.theme_text_badge_dark{
  color: $text-dark !important;
}






.bg-gradient-dark {
  --bs-bg-opacity: 1;
  background-image: linear-gradient(90deg, $dark-dark, $dark-normal);
}








.btn-color-dark{
  background-color: $btn-color-dark !important;
  border: 1px solid $btn-color-dark !important;
}

.btn-color-dark:hover,
.btn-color-dark:active{
  background-color: $btn-color-dark-hover !important;
}

.btn-badge-open-dark{
  background-color: $dark-cyan;
  border: none;
}

.btn-badge-closed-dark{
  background-color: $btn-color-dark;
  border: none;
}

.btn-badge-archived-dark{
  background-color: $btn-color-dark;
  border: none;
}

.btn-badge-yellow-dark,
.btn-badge-yellow-dark:disabled
{
  background-color: $dark-dark;
  border: 1px solid $dark-dark;
}

.btn-badge-yellow-dark:hover,
.btn-badge-yellow-dark:active{
  background-color: $btn-dark-yellow-hover !important;
  border: 1px solid $btn-dark-yellow-hover;
}


.btn-color-red-dark{
  background-color: $text-dark-red;
  border: 1px solid $text-dark-red;
}

.btn-color-red-dark:hover,
.btn-color-red-dark:active
{
  background-color: $dark-red-hover !important;
  border: 1px solid $dark-red-hover !important;
}

.btn-green-dark,
.btn-green-dark:disabled
{
  background-color: $btn-dark-green !important;
  border: 1px solid $btn-dark-green !important;
}

.btn-green-dark:hover,
.btn-green-dark:active{
  background-color: $btn-dark-green-hover !important;
}

.btn-yellow-dark,
.btn-yellow-dark:disabled
{
  background-color: $text-dark-yellow !important;
  border: none;
}

.btn-yellow-dark:hover,
.btn-yellow-dark:active{
  background-color: $btn-dark-yellow !important ;
}

.btn-list-color-dark .nav-link{
  background-color: $btn-color-dark !important;
  border-radius: 5px !important;
  color: $text-dark;
}

.btn-list-color-dark .nav-link:hover{
  background-color: $btn-list-dark !important;
}

.btn-board-dark label,
.btn-board-dark:hover label
{
  color: $text-dark !important;
  border-color: $btn-color-dark !important;
}

.btn-board-dark .btn-check:checked + .btn{
  background-color: $btn-color-dark !important;
}


.btn-outline-dark,
.btn-outline-dark:hover{
  color: $dark-page-link !important;
  border-color: $btn-color-dark !important;
}

.theme_dark_dark .btn-check:checked + .btn{
  background-color: $text-dark-light!important;
}

.btn-color-dark-dark{
  background-color: $dark-dark !important;
  border: 1px solid $dark-dark !important;
  color: $text-white !important;
}

.btn-color-dark-dark:hover,
.btn-color-dark-dark:active{
  background-color: $btn-color-dark-dark !important;
}

.btn-chart-dark{
  background-color: $btn-color-dark;
  border-color: $btn-color-dark;
}

.btn-chart-dark:hover,
.btn-chart-dark:active{
  background-color: $btn-color-dark-hover;
  border-color: $btn-color-dark-hover;
}



.theme_table_lean_dark tbody tr td{
  background-color: $dark-normal;
  color: $text-dark !important;
  cursor: default;
}

.theme_table_lean_dark tbody tr td a {
  color: $text-dark !important;
  cursor: default;
}

.theme_table_dark tbody tr:nth-child(even) td,
.theme_table_dark tbody tr:nth-child(even) td:active {
  background-color: $dark-normal !important;
  color: $text-dark;
  border: solid 1px $dark-table-border;
  cursor: default;
}

.theme_table_dark tbody tr:nth-child(odd) td,
.theme_table_dark tbody tr:nth-child(odd) td:active {
  background-color: $dark-light !important;
  color: $text-dark;
  border: solid 1px $dark-table-border;
  cursor: default;
}

.theme_table_dark  tr:nth-child(even) td {
  background-color: $dark-normal;
  color: $text-dark;
  border: solid 1px $dark-table-border;
  cursor: default;
}

.theme_table_dark tr:nth-child(odd) td {
  background-color: $dark-light;
  color: $text-dark;
  border: solid 1px $dark-table-border;
  cursor: default;
}

.theme_table_head_dark tr th {
  background-color: $dark-dark;
  border: solid 1px $dark-table-border;
  color: $text-dark-light;
  cursor: default;
}

.theme_table_head_dark tr th span:focus{
  color: $text-dark;
  cursor: default;
}


.theme_icon_dark{
  color: $text-dark;
}

.theme_border_dark{
  border-color: $text-dark;
}

.theme_set_border_dark{
  stroke: $dark-dark;

}


.theme_text_compagny_dark{
  stroke: $text-dark;
  fill: $text-dark;
  color: $text-dark;
}

.theme_compagny_stroke_dark .rd3t-link{
  color: $text-dark;
  stroke: $text-dark !important;
  background-color: $text-dark;
}




























/*Theme light*/

.theme_light_light{
  background-color: #ffffff;
  color: #374253;
}

.theme_light_normal{
  background-color: #ffffff;
  color: #374253;
}

.theme_light_dark{
  background-color: #f0f4f7 !important;
  color: #374253 ;
}


.theme_light_dark .dropdown-item:hover{
  background-color: #ffffff;
  color: #374253;

}

.theme_navbar_light{
  background-color: #001481 !important;
  color: #374253 ;
}

.theme_board_light_normal{
  background-color: #ffffff;
}

.theme_board_light_light{
  background-color: #f0f4f7;
}

.theme_board_light_dark{
  background-color: #ffffff !important;
}


.theme_light_red{
  background-color: #DC3545;
  border: 1px solid #DC3545;
}

.theme_light_green{
  background-color: #28A745;
  border:none;
}


.theme_calendar_light_light{
  background-color: #ffffff;
  color: #374253;
  border:none;
}

.theme_calendar_light_normal{
  color: #374253;
  border:none;
}

.theme_calendar_light_dark{
  background-color: #ffffff;
  color: #374253;
  border:none;
}



.theme_text_light{
  color: #374253 !important;
}

.theme_text_light:disabled{
  color: #000015 !important;
}

.theme_text_light:hover{
  color: #000015;

}

.theme_text_light:active{
  color: #000015;
}

.theme_text_red_light{
  color: #DC3545 !important;
}

.theme_text_green_light{
  color: #28A745 !important;
}

.theme_text_yellow_light{
  color: #FFC107 !important;
}

.theme_text_cyan_light{
  color: #ffffff !important;
}


.theme_home_text_light{
  color: #001481;
}

.theme_text_light_light{
  color: #d6d6d6 !important;
}

.theme_link_light{
  color: #001481;
}

.theme_link_light a{
  color: #001481 !important;
}

.theme_link_light span{
  color: #2c384ab6;
}

.theme_text_noddle_light{
  color: #001481;
}

.theme_text_badge_light{
  color: #ffffff !important;
}




.theme_lean_light_dark{
  color: white;
  background-color: $dark-cyan !important;
  border-color: $dark-cyan;
}

.theme_lean_light_dark:hover{
  color: white;
  background-color: #3cd2cd !important;
  border-color: #3cd2cd;
}

.theme_lean_light_dark:active{
  color: white;
  background-color: #3cd2cd !important;
  border-color: #3cd2cd !important;
}


.bg-gradient-light {
  --bs-bg-opacity: 1;
  background-image: linear-gradient(90deg,#001481,#0484c4);
}

.btn-color-light{
  background-color: #001481;
}

.btn-close-light{
  background-color: #9b9b9b;
}

.btn-color-light:hover{
  background-color: #0018a1;
}

.btn-badge-open-light{
  background-color: $dark-cyan;
  color: #fff !important;
  border: none;
}

.btn-badge-closed-light{
  background-color: #001481;
  border: none;
}

.btn-badge-archived-light{
  background-color: #636f83;
  border: none;
}

.btn-badge-yellow-light,
.btn-badge-yellow-light:disabled
{
  background-color: #FFC107;
  border: 1px solid #FFC107;
}

.btn-badge-yellow-light:hover,
.btn-badge-yellow-light:active{
  background-color: #e6ae06 !important;
  border: 1px solid #e6ae06;
}


.btn-color-red-light{
  background-color: #DC3545;
  border: 1px solid #DC3545;
}

.btn-color-red-light:hover,
.btn-color-red-light:active
{
  background-color: #ba2e3c !important;
  border: 1px solid #ba2e3c !important;
}

.btn-green-light{
  background-color: #28A73F;
  border: none;
  color: white !important;
}

.btn-green-light:hover,
.btn-green-light:active{
  background-color: #28A73F !important;
}


.btn-group-light .btn-check:checked + .btn{
  background-color: #001481 !important;
  border-color: #001481 !important;
  color: #fff !important;
}

.btn-outline-light{
  color: #001481 !important;
  border-color: #001481 !important;
}

.btn-color-light-dark{
  background-color: #001481;
  border: none;
  color: white !important;
}

.btn-color-light-dark:hover,
.btn-color-light-dark:active{
  background-color: #051b98 !important;
}

.btn-chart-light{
background-color: #636f83 !important;
border-color: #636f83 !important;
}

.btn-chart-light:hover,
.btn-chart-light:active{
  background-color: #8c8c8c !important;
border-color: #8c8c8c !important;
}


.theme_table_light tbody tr:nth-child(even) td {
  background-color: #fff;
  color: #374253;
  border: solid 1px #ebedef;
  cursor: default;
}

.theme_table_light tbody tr:nth-child(odd) td {
  background-color: #faf1f1;
  color: #374253;
  border: solid 1px #ebedef;
  cursor: default;
}

.theme_table_head_light tr th {
  background-color: #f0f4f7;
  border: solid 1px #ccd1d7fc;
  color: black;
  cursor: default;
}

.theme_set_border_light{
  stroke: white;

}

.theme_text_compagny_light{
  stroke: #374253;
  fill: #374253;
}

.theme_compagny_stroke_light .rd3t-link{
  stroke: #000 !important;
}






/*Theme purple*/



$purple-light: #524389;
$purple-normal: #40346b;
$purple-dark: #241e3d;

$text-white:#fff ;
$text-purple: #dedff5;
$text-purple-light:#eeeffa;
$text-purple-red:#bd4854;
$text-purple-yellow:#bb9c3f;
$text-purple-light-cyan:#c3ebea;

$purple-page-link :#1e1a33;
$purple-page-item: #383860;

$link-purple : #867cb7;

$purple-light-active: #6e6298 ;
$purple-cyan: #37BFBB ;
$purple-red: #c7424f;
$purple-green: #3f9854;

$purple-red-hover:#8f363f;

$btn-color-purple:#6d6295;
$btn-color-purple-dark:#2c2643;
$btn-color-purple-hover:#62568d;
$btn-purple-lean-hover: #62568d;
$btn-purple-green: #3a7948;
$btn-purple-green-hover:#368247;
$btn-purple-yellow-hover: #3c3c3c;
$btn-purple-yellow: #caa843;

$btn-list-purple:#544a79;

$purple-table-border:#64588d;

$purple-calendar-cell-disabled: #64588d;
$purple-calendar-header : #463e67;






.theme_purple_light{
  background-color: $purple-light;
  color: $text-purple;
  border:none;
}

.theme_purple_light:hover{
  background-color: $purple-light;
}

.theme_purple_light:active{
  background-color: $purple-light-active !important;
}


.theme_slide_purple_light{
  background-color: $purple-light;
  color: $text-purple;
  border:none;
}

.theme_purple_normal{
  background-color: $purple-normal !important;
  color: $text-purple !important;
  border:none;
}

.theme_purple_normal .btn-close{
  background-color: $text-purple;
  color: $text-purple;
}

.theme_purple_normal .rct-icon{
  color: $text-purple;
}

.theme_purple_normal .form-check-input:checked {
  color: $text-purple !important;
  background-color: $purple-cyan !important;
  border-color: $purple-cyan !important;
}

.theme_purple_normal .form-control,
.theme_purple_normal .form-select,
.theme_purple_normal .form-multi-select,
.theme_purple_normal .form-multi-select-tag,
.theme_purple_normal .form-multi-select-search,
.theme_purple_normal .form-multi-select-options,
.theme_purple_normal .form-control::placeholder,
.theme_purple_normal .form-select::placeholder,
.theme_purple_normal .form-multi-select::placeholder{
  color: $text-purple !important;
  background-color: $purple-dark ;
  border-color: $purple-normal !important;
}

.theme_purple_normal .form-range{
  background-color: $purple-normal !important;
  color: $text-purple !important;
  border:none;
}

.theme_purple_normal input{
  color: $text-purple !important;
  background-color: $purple-dark !important;
  border-color: $purple-normal !important;
}

.theme_purple_normal .form-control:disabled,
.theme_purple_normal .form-select:disabled,
.theme_purple_normal .form-multi-select:disabled,
.theme_purple_normal .form-multi-select-tag:disabled,
.theme_purple_normal .form-multi-select-search:disabled,
.theme_purple_normal .form-multi-select-options:disabled,
.theme_purple_normal .form-multi-select.disabled,
.theme_purple_normal textarea:disabled::placeholder,
.theme_purple_normal .form-control:disabled::placeholder
{
  color: $text-purple !important;
  background-color: $purple-light !important;
  border-color: $purple-normal !important;
}

.theme_purple_normal input:disabled{
  color: $text-purple !important;
  background-color: $purple-light !important;
  border-color: $purple-normal !important;
}

.theme_purple_normal .form-multi-select-dropdown{
  border-color: $purple-light  !important;
}

.theme_purple_normal .form-multi-select-option.form-multi-selected::before,
.theme_purple_normal .form-multi-select-option.form-multi-select-option-with-checkbox::before{
  background-color: $purple-dark !important;
  border-color: $purple-dark  !important;
}

.theme_purple_normal .calendar-header-cell-inner,
.theme_purple_normal .calendar-cell-inner,
.theme_purple_normal .input-group-text,
.theme_purple_normal .dropdown-menu{
  color: $text-purple !important;
  background-color: $purple-dark !important;
  border-color: $purple-normal !important;
}

.theme_purple_normal .calendar-cell.disabled .calendar-cell-inner{
  color: $purple-calendar-cell-disabled !important;
}

.theme_purple_normal .calendar-cell-inner:active,
.theme_purple_normal .calendar-cell-inner:hover{
  color: $purple-dark !important;
  background-color: $purple-light !important;
}

.theme_purple_normal .calendar-nav ,
.theme_purple_normal .calendar-nav .btn span,
.theme_purple_normal .calendar-nav .btn .btn-transparent .calendar-nav-icon-double-next,
.theme_purple_normal .picker-footer

{
  color: $purple-normal !important;
  background-color: $text-purple !important;
  border-color: $purple-normal !important;
}

.theme_purple_normal .picker-footer button{
  color: $text-purple !important;
  background-color: $purple-dark !important;
  border-color: $purple-normal !important;
}



.theme_purple_normal .form-multi-select-option{
  color: $text-purple !important;
  background-color: $purple-light !important;
  border-color: $purple-normal !important;
  margin: 5px 0px;
}

.theme_purple_normal .form-multi-select button{

  color: $text-purple !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $purple-dark !important; /* couleur de fond de l'input lorsqu'il est en focus ou actif */
  border-color: $purple-dark;
}


.theme_purple_normal .pagination .page-item:disabled a,
.theme_purple_normal .pagination .page-item a,
.theme_board_purple_normal .pagination .page-item:disabled a,
.theme_board_purple_normal .pagination .page-item a{
  background-color: $purple-light !important;
  color: $text-purple !important;
  border:1px solid $purple-light;
}

.theme_purple_normal .page-link,
.theme_board_purple_normal .page-link{
  background-color: $purple-page-link !important;
  color: $text-purple !important;
  border:1px solid $purple-page-link;
}


.theme_purple_dark{
  background-color: $purple-dark !important;
  color: $text-purple;
  border:none;
}

.theme_purple_dark .accordion-header .accordion-button{
  background-color: $purple-light;
  color: $text-purple;
  border:none;
  box-shadow: none;
}


.theme_purple_dark .dropdown-item:hover{
  background-color: $purple-normal;
  color: $text-purple;
  cursor: pointer;

}

.theme_purple_dark::placeholder{
  color: $text-purple;
}

.theme_purple_dark input:focus,
.theme_purple_dark input:active {
  color: $text-purple !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $purple-dark !important;
}

.theme_purple_dark:focus,
.theme_purple_dark:active {
  color: $text-purple !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $purple-dark !important; /* couleur de fond de l'input lorsqu'il est en focus ou actif */
}

.theme_navbar_purple{
  background-color: $purple-dark !important;
  color: $text-purple ;
}

.theme_lean_purple_dark{
  color: $text-purple;
  background-color: $purple-dark !important;
  border: $purple-dark solid 1px;
}

.theme_lean_purple_dark:hover{
  color: $text-purple;
  background-color: $btn-purple-lean-hover !important;
  border: $btn-purple-lean-hover solid 1px;
}


.theme_lean_purple_dark:active{
  color: $text-purple;
  background-color: $btn-purple-lean-hover !important;
  border: $btn-purple-lean-hover solid 1px !important;
}

.theme_slideshow_purple_dark{
  background-color: $purple-dark !important;
  padding-top : 10px;
  color: $text-purple;
  border:none;
}


.theme_slideshow_purple_normal{
  background-color: $purple-normal !important;
  padding-top : 10px;
  color: $text-purple;
  border:none;
}

 .hori-timeline .events{
  border-top:none;
}

.theme_purple_red{
  background-color: $purple-red;
  border: 1px solid $purple-red;
}

.theme_purple_green{
  background-color: $purple-green;
  border:none;
}


.theme_board_purple_normal{
  background-color: $purple-dark !important;
  color:$text-purple;
  border-color: $purple-dark !important;

}

.theme_board_purple_normal input,
.theme_board_purple_normal input::placeholder,
.theme_board_purple_normal input:focus,
.theme_board_purple_normal .form-select
{
  background-color: $purple-normal !important;
  color:$text-purple;
  border-color: $purple-light !important;

}

.theme_board_purple_light{
  background-color: $purple-light;
  color:$text-purple;
  border-color: $purple-light !important;
}

.theme_board_purple_dark{
  background-color: $purple-dark !important;
  color:$text-purple;
  border-color: $purple-dark !important;
}


.theme_purple_dark .page-item a,
.theme_purple_normal .page-item a{
  background-color: $purple-light !important;
  color:$text-purple;
  border-color: $purple-light !important;
}


.theme_purple_dark .page-item span,
.theme_purple_normal .page-item span{
  background-color: $purple-page-item !important;
  color:$text-purple;
  border-color: $purple-page-item !important;
}



.theme_text_noddle_purple{
  color: $text-purple !important;
}


.theme_calendar_purple_light,
.theme_calendar_purple_light button
{
  background-color: $purple-light !important;
  color: $text-purple !important;
  border:none;
  box-shadow: none !important;
}

.theme_calendar_purple_normal{
  background-color: $purple-normal;
  color: $text-purple;
  border:none;
}

.theme_calendar_purple_dark{
  background-color: $purple-dark;
  color: $text-purple;
  border:none;
}

.theme_calendar_purple_dark .fc-daygrid-day-number{
  color: $text-purple;
}

.theme_calendar_purple_dark .fc-button,
.theme_calendar_purple_dark .form-check-input:checked,
.theme_calendar_purple_dark .form-check-input::before
{
  background-color: $purple-cyan !important;
  border-color: $purple-cyan !important;
}

.theme_calendar_purple_dark .fc-day-today{
  background-color: $purple-normal !important;
}

.theme_calendar_purple_dark .fc-col-header-cell,
.theme_calendar_purple_dark .fc-col-header-cell a
{
  color: $text-purple;
  background-color: $purple-calendar-header !important;
  border-color: $purple-calendar-header !important;
}

.theme_calendar_purple_dark .fc-daygrid-day-frame:hover,
.theme_calendar_purple_dark .fc-daygrid-day-frame:active{
  background-color: $purple-light;
}

.theme_user_checkbox_purple{
  background-color: $purple-dark !important;
  border-color: $purple-dark !important;
}














.theme_text_purple{
  color: $text-purple !important;
}

.theme_text_purple:disabled{
  color: $text-purple !important;
}

.theme_text_purple:hover{
  color: $text-purple-light;

}

.theme_text_purple:active{
  color: $text-purple;
}

.theme_text_red_purple{
  color: $text-purple-red !important;
}

.theme_text_green_purple{
  color: $purple-green !important;
}

.theme_text_yellow_purple{
  color: $text-purple-yellow !important;
}

.theme_text_cyan_purple{
  color: $text-purple-light-cyan !important;
}

.theme_home_text_purple{
  color: $text-purple;
}

.theme_text_light_purple{
  color: $text-purple-light !important;
}

.theme_link_purple{
  color: $link-purple !important;
}

.theme_link_purple a{
  color: $text-purple;
}

.theme_link_purple span{
  color: $text-purple;
}

.theme_text_badge_purple{
  color: $text-purple !important;
}






.bg-gradient-purple {
  --bs-bg-opacity: 1;
  background-image: linear-gradient(90deg, $purple-dark, $purple-normal);
}








.btn-color-purple{
  background-color: $btn-color-purple !important;
  border: 1px solid $btn-color-purple !important;
}

.btn-color-purple:hover,
.btn-color-purple:active{
  background-color: $btn-color-purple-hover !important;
}

.btn-badge-open-purple{
  background-color: $purple-cyan;
  border: none;
}

.btn-badge-closed-purple{
  background-color: $btn-color-purple;
  border: none;
}

.btn-badge-archived-purple{
  background-color: $btn-color-purple;
  border: none;
}

.btn-badge-yellow-purple,
.btn-badge-yellow-purple:disabled
{
  background-color: $purple-dark;
  border: 1px solid $purple-dark;
}

.btn-badge-yellow-purple:hover,
.btn-badge-yellow-purple:active{
  background-color: $btn-purple-yellow-hover !important;
  border: 1px solid $btn-purple-yellow-hover;
}


.btn-color-red-purple{
  background-color: $text-purple-red;
  border: 1px solid $text-purple-red;
}

.btn-color-red-purple:hover,
.btn-color-red-purple:active
{
  background-color: $purple-red-hover !important;
  border: 1px solid $purple-red-hover !important;
}

.btn-green-purple,
.btn-green-purple:disabled
{
  background-color: $btn-purple-green !important;
  border: 1px solid $btn-purple-green !important;
}

.btn-green-purple:hover,
.btn-green-purple:active{
  background-color: $btn-purple-green-hover !important;
}

.btn-yellow-purple,
.btn-yellow-purple:disabled
{
  background-color: $text-purple-yellow !important;
  border: none;
}

.btn-yellow-purple:hover,
.btn-yellow-purple:active{
  background-color: $btn-purple-yellow !important ;
}

.btn-list-color-purple .nav-link{
  background-color: $btn-color-purple !important;
  border-radius: 5px !important;
  color: $text-purple;
}

.btn-list-color-purple .nav-link:hover{
  background-color: $btn-list-purple !important;
}

.btn-board-purple label,
.btn-board-purple:hover label
{
  color: $text-purple !important;
  border-color: $btn-color-purple !important;
}

.btn-board-purple .btn-check:checked + .btn{
  background-color: $btn-color-purple !important;
}


.btn-outline-purple,
.btn-outline-purple:hover
{
  color: $text-purple !important;
  border-color: $btn-color-purple !important;
}

.theme_purple_dark .btn-check:checked + .btn{
  background-color: $btn-color-purple !important;
  color: $text-purple-light !important;
}

.btn-color-purple-dark{
  background-color: $purple-dark !important;
  border: 1px solid $purple-dark !important;
  color: $text-white !important;
}

.btn-color-purple-dark:hover,
.btn-color-purple-dark:active{
  background-color: $btn-color-purple-dark !important;
}

.btn-chart-purple{
  background-color: $btn-color-purple;
  border-color: $btn-color-purple !important;
}

.btn-chart-purple:hover,
.btn-chart-purple:active{
  background-color: $btn-color-purple-hover;
  border-color: $btn-color-purple-hover  !important;
}



.theme_table_lean_purple tbody tr td {
  background-color: $purple-normal;
  color: $text-purple !important;
  cursor: default;
}

.theme_table_lean_purple tbody tr td a {
  color: $text-purple !important;
  cursor: default;
}

.theme_table_purple tbody tr:nth-child(even) td {
  background-color: $purple-normal;
  color: $text-purple;
  border: solid 1px $purple-table-border;
  cursor: default;
}

.theme_table_purple tbody tr:nth-child(odd) td {
  background-color: $purple-light;
  color: $text-purple;
  border: solid 1px $purple-table-border;
  cursor: default;
}

.theme_table_purple  tr:nth-child(even) td {
  background-color: $purple-normal;
  color: $text-purple;
  border: solid 1px $purple-table-border;
  cursor: default;
}

.theme_table_purple tr:nth-child(odd) td {
  background-color: $purple-light;
  color: $text-purple;
  border: solid 1px $purple-table-border;
  cursor: default;
}

.theme_table_head_purple tr th {
  background-color: $purple-dark;
  border: solid 1px $btn-color-purple-hover;
  color: $text-purple-light;
  cursor: default;
}

.theme_table_head_purple tr th span:focus{
  color: $text-purple;
  cursor: default;
}


.theme_icon_purple{
  color: $text-purple;
}

.theme_border_purple{
  border-color: $text-purple  !important;
}

.theme_set_border_purple{
  stroke: $purple-dark;

}


.theme_text_compagny_purple{
  stroke: $text-purple;
  fill: $text-purple;
  color: $text-purple;
}

.theme_compagny_stroke_purple .rd3t-link{
  color: $text-purple;
  stroke: $text-purple !important;
  background-color: $text-purple;
}











/* Theme bleue */

$blue-light: #d9f0fd;
$blue-normal: #b4e1fb;
$blue-dark: #75b9e0;

$text-white:#fff ;
$text-blue: #192931;
$text-blue-light:#b3e3ff;
$text-blue-red:#bd4854;
$text-blue-yellow:#bb9c3f;
$text-blue-light-cyan:#c3ebea;

$blue-page-link :#5b90ae;
$blue-page-item: #d9f0fd;

$link-blue : #b8c9d4;

$blue-light-active: #9cc1d6 ;
$blue-cyan: #37BFBB ;
$blue-red: #c7424f;
$blue-green: #3f9854;

$blue-red-hover:#8f363f;

$btn-color-blue:#70a5c4;
$btn-color-blue-dark:#466e85;
$btn-color-blue-hover:#74a6c4;
$btn-blue-lean-hover: #74a6c4;
$btn-blue-green: #3a7948;
$btn-blue-green-hover:#368247;
$btn-blue-yellow-hover: #3c3c3c;
$btn-blue-yellow: #caa843;

$btn-list-blue:#75b9e0;

$blue-table-border:#afd7ee;

$blue-calendar-cell-disabled: #d9f0fd;
$blue-calendar-header : #75b9e0;






.theme_blue_light{
  background-color: $blue-light;
  color: $text-blue;
  border:none;
}

.theme_blue_light:hover{
  background-color: $blue-light;
}

.theme_blue_light:active{
  background-color: $blue-light-active !important;
}


.theme_slide_blue_light{
  background-color: $blue-light;
  color: $text-blue;
  border:none;
}

.theme_blue_normal{
  background-color: $blue-normal !important;
  color: $text-blue !important;
  border:none;
}

.theme_blue_normal .btn-close{
  background-color: $text-blue;
  color: $text-blue;
}

.theme_blue_normal .rct-icon{
  color: $text-blue;
}

.theme_blue_normal .form-check-input:checked {
  color: $text-blue !important;
  background-color: $blue-cyan !important;
  border-color: $blue-cyan !important;
}

.theme_blue_normal .form-control,
.theme_blue_normal .form-select,
.theme_blue_normal .form-multi-select,
.theme_blue_normal .form-multi-select-tag,
.theme_blue_normal .form-multi-select-search,
.theme_blue_normal .form-multi-select-options,
.theme_blue_normal .form-control::placeholder,
.theme_blue_normal .form-select::placeholder,
.theme_blue_normal .form-multi-select::placeholder{
  color: $text-blue !important;
  background-color: $blue-dark !important;
  border-color: $blue-normal !important;
}

.theme_blue_normal .form-range{
  background-color: $blue-normal !important;
  color: $text-blue !important;
  border:none;
}

.theme_blue_normal input{
  color: $text-blue !important;
  background-color: $blue-dark !important;
  border-color: $blue-normal !important;
}

.theme_blue_normal .form-control:disabled,
.theme_blue_normal .form-select:disabled,
.theme_blue_normal .form-multi-select:disabled,
.theme_blue_normal .form-multi-select-tag:disabled,
.theme_blue_normal .form-multi-select-search:disabled,
.theme_blue_normal .form-multi-select-options:disabled,
.theme_blue_normal .form-multi-select.disabled,
.theme_blue_normal textarea:disabled::placeholder,
.theme_blue_normal .form-control:disabled::placeholder
{
  color: $text-blue !important;
  background-color: $blue-light !important;
  border-color: $blue-normal !important;
}

.theme_blue_normal input:disabled{
  color: $text-blue !important;
  background-color: $blue-light !important;
  border-color: $blue-normal !important;
}

.theme_blue_normal .form-multi-select-dropdown{
  border-color: $blue-light  !important;
}

.theme_blue_normal .form-multi-select-option.form-multi-selected::before,
.theme_blue_normal .form-multi-select-option.form-multi-select-option-with-checkbox::before{
  background-color: $blue-dark !important;
  border-color: $blue-dark  !important;
}

.theme_blue_normal .calendar-header-cell-inner,
.theme_blue_normal .calendar-cell-inner,
.theme_blue_normal .input-group-text,
.theme_blue_normal .dropdown-menu{
  color: $text-blue !important;
  background-color: $blue-dark !important;
  border-color: $blue-normal !important;
}

.theme_blue_normal .calendar-cell.disabled .calendar-cell-inner{
  color: $blue-calendar-cell-disabled !important;
}

.theme_blue_normal .calendar-cell-inner:active,
.theme_blue_normal .calendar-cell-inner:hover{
  color: $blue-dark !important;
  background-color: $blue-light !important;
}

.theme_blue_normal .calendar-nav ,
.theme_blue_normal .calendar-nav .btn span,
.theme_blue_normal .calendar-nav .btn .btn-transparent .calendar-nav-icon-double-next,
.theme_blue_normal .picker-footer

{
  color: $blue-normal !important;
  background-color: $blue-light !important;
  border-color: $blue-normal !important;
}

.theme_blue_normal .picker-footer button{
  color: $text-blue !important;
  background-color: $blue-dark !important;
  border-color: $blue-normal !important;
}


.theme_blue_normal .form-multi-select-option{
  color: $text-blue !important;
  background-color: $blue-light !important;
  border-color: $blue-normal !important;
  margin: 5px 0px;
}

.theme_blue_normal .form-multi-select button{

  color: $text-blue !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $blue-dark !important; /* couleur de fond de l'input lorsqu'il est en focus ou actif */
  border-color: $blue-dark  !important;
}


.theme_blue_normal .pagination .page-item:disabled a,
.theme_blue_normal .pagination .page-item a,
.theme_board_blue_normal .pagination .page-item:disabled a,
.theme_board_blue_normal .pagination .page-item a{
  background-color: $blue-light !important;
  color: $text-blue !important;
  border:1px solid $blue-light;
}

.theme_blue_normal .page-link,
.theme_board_blue_normal .page-link{
  background-color: $blue-page-link !important;
  color: $text-blue !important;
  border:1px solid $blue-page-link;
}


.theme_blue_dark{
  background-color: $blue-dark !important;
  color: $text-blue;
  border:none;
}

.theme_blue_dark .accordion-header .accordion-button{
  background-color: $blue-light;
  color: $text-blue;
  border:none;
  box-shadow: none;
}


.theme_blue_dark .dropdown-item:hover{
  background-color: $blue-normal;
  color: $text-blue;
  cursor: pointer;

}

.theme_blue_dark::placeholder{
  color: $text-blue;
}

.theme_blue_dark input:focus,
.theme_blue_dark input:active {
  color: $text-blue !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $blue-dark !important;
}

.theme_blue_dark:focus,
.theme_blue_dark:active {
  color: $text-blue !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $blue-dark !important; /* couleur de fond de l'input lorsqu'il est en focus ou actif */
}

.theme_navbar_blue{
  background-color: $blue-dark !important;
  color: $text-blue ;
}

.theme_lean_blue_dark{
  color: $text-blue;
  background-color: $blue-dark !important;
  border: $blue-dark solid 1px;
}

.theme_lean_blue_dark:hover{
  color: $text-blue;
  background-color: $btn-blue-lean-hover !important;
  border: $btn-blue-lean-hover solid 1px;
}


.theme_lean_blue_dark:active{
  color: $text-blue;
  background-color: $btn-blue-lean-hover !important;
  border: $btn-blue-lean-hover solid 1px !important;
}

.theme_slideshow_blue_dark{
  background-color: $blue-dark !important;
  padding-top : 10px;
  color: $text-blue;
  border:none;
}


.theme_slideshow_blue_normal{
  background-color: $blue-normal !important;
  padding-top : 10px;
  color: $text-blue;
  border:none;
}

 .hori-timeline .events{
  border-top:none;
}

.theme_blue_red{
  background-color: $blue-red;
  border: 1px solid $blue-red;
}

.theme_blue_green{
  background-color: $blue-green;
  border:none;
}


.theme_board_blue_normal{
  background-color: $blue-dark !important;
  color:$text-blue;
  border-color: $blue-dark !important;

}

.theme_board_blue_normal input,
.theme_board_blue_normal input::placeholder,
.theme_board_blue_normal input:focus,
.theme_board_blue_normal .form-select
{
  background-color: $blue-normal !important;
  color:$text-blue;
  border-color: $blue-light !important;

}

.theme_board_blue_light{
  background-color: $blue-light;
  color:$text-blue;
  border-color: $blue-light !important;
}

.theme_board_blue_dark{
  background-color: $blue-dark !important;
  color:$text-blue;
  border-color: $blue-dark !important;
}


.theme_blue_dark .page-item a,
.theme_blue_normal .page-item a{
  background-color: $blue-light !important;
  color:$text-blue;
  border-color: $blue-light !important;
}


.theme_blue_dark .page-item span,
.theme_blue_normal .page-item span{
  background-color: $blue-page-item !important;
  color:$text-blue;
  border-color: $blue-page-item !important;
}



.theme_text_noddle_blue{
  color: $text-blue !important;
}


.theme_calendar_blue_light,
.theme_calendar_blue_light button
{
  background-color: $blue-light !important;
  color: $text-blue !important;
  border:none;
  box-shadow: none !important;
}

.theme_calendar_blue_normal{
  background-color: $blue-normal;
  color: $text-blue;
  border:none;
}

.theme_calendar_blue_dark{
  background-color: $blue-dark;
  color: $text-blue;
  border:none;
}

.theme_calendar_blue_dark .fc-daygrid-day-number{
  color: $text-blue;
}

.theme_calendar_blue_dark .fc-button,
.theme_calendar_blue_dark .form-check-input:checked,
.theme_calendar_blue_dark .form-check-input::before
{
  background-color: $blue-cyan !important;
  border-color: $blue-cyan !important;
}

.theme_calendar_blue_dark .fc-day-today{
  background-color: $blue-normal !important;
}

.theme_calendar_blue_dark .fc-col-header-cell,
.theme_calendar_blue_dark .fc-col-header-cell a
{
  color: $text-blue;
  background-color: $blue-calendar-header !important;
  border-color: $blue-calendar-header !important;
}

.theme_calendar_blue_dark .fc-daygrid-day-frame:hover,
.theme_calendar_blue_dark .fc-daygrid-day-frame:active{
  background-color: $blue-light;
}

.theme_user_checkbox_blue{
  background-color: $blue-dark !important;
  border-color: $blue-dark !important;
}














.theme_text_blue{
  color: $text-blue !important;
}

.theme_text_blue:disabled{
  color: $text-blue !important;
}

.theme_text_blue:hover{
  color: $text-blue-light;

}

.theme_text_blue:active{
  color: $text-blue;
}

.theme_text_red_blue{
  color: $text-blue-red !important;
}

.theme_text_green_blue{
  color: $blue-green !important;
}

.theme_text_yellow_blue{
  color: $text-blue-yellow !important;
}

.theme_text_cyan_blue{
  color: $text-blue-light-cyan !important;
}

.theme_home_text_blue{
  color: $text-blue;
}

.theme_text_light_blue{
  color: $text-blue-light !important;
}

.theme_link_blue{
  color: $link-blue !important;
}

.theme_link_blue a{
  color: $text-blue;
}

.theme_link_blue span{
  color: $text-blue;
}

.theme_text_badge_blue{
  color: $text-blue !important;
}






.bg-gradient-blue {
  --bs-bg-opacity: 1;
  background-image: linear-gradient(90deg, $blue-dark, $blue-normal);
}








.btn-color-blue{
  background-color: $btn-color-blue !important;
  border: 1px solid $btn-color-blue !important;
}

.btn-color-blue:hover,
.btn-color-blue:active{
  background-color: $btn-color-blue-hover !important;
}

.btn-badge-open-blue{
  background-color: $blue-cyan;
  border: none;
}

.btn-badge-closed-blue{
  background-color: $btn-color-blue;
  border: none;
}

.btn-badge-archived-blue{
  background-color: $btn-color-blue;
  border: none;
}

.btn-badge-yellow-blue,
.btn-badge-yellow-blue:disabled
{
  background-color: $blue-dark;
  border: 1px solid $blue-dark;
}

.btn-badge-yellow-blue:hover,
.btn-badge-yellow-blue:active{
  background-color: $btn-blue-yellow-hover !important;
  border: 1px solid $btn-blue-yellow-hover;
}


.btn-color-red-blue{
  background-color: $text-blue-red;
  border: 1px solid $text-blue-red;
}

.btn-color-red-blue:hover,
.btn-color-red-blue:active
{
  background-color: $blue-red-hover !important;
  border: 1px solid $blue-red-hover !important;
}

.btn-green-blue,
.btn-green-blue:disabled
{
  background-color: $btn-blue-green !important;
  border: 1px solid $btn-blue-green !important;
}

.btn-green-blue:hover,
.btn-green-blue:active{
  background-color: $btn-blue-green-hover !important;
}

.btn-yellow-blue,
.btn-yellow-blue:disabled
{
  background-color: $text-blue-yellow !important;
  border: none;
}

.btn-yellow-blue:hover,
.btn-yellow-blue:active{
  background-color: $btn-blue-yellow !important ;
}

.btn-list-color-blue .nav-link{
  background-color: $btn-color-blue !important;
  border-radius: 5px !important;
  color: $text-blue;
}

.btn-list-color-blue .nav-link:hover{
  background-color: $btn-list-blue !important;
}

.btn-board-blue label,
.btn-board-blue:hover label
{
  color: $text-blue !important;
  border-color: $btn-color-blue !important;
}

.btn-board-blue .btn-check:checked + .btn{
  background-color: $btn-color-blue !important;
}


.btn-outline-blue,
.btn-outline-blue:hover{
  color: $text-blue-light !important ;
  border-color: $btn-color-blue !important;
}

.theme_blue_dark .btn-check:checked + .btn{
  background-color: $btn-color-blue !important;
  color: $text-blue !important;
}

.btn-color-blue-dark{
  background-color: $blue-dark !important;
  border: 1px solid $blue-dark !important;
  color: $text-white !important;
}

.btn-color-blue-dark:hover,
.btn-color-blue-dark:active{
  background-color: $btn-color-blue-dark !important;
}

.btn-chart-blue{
  background-color: $btn-color-blue;
  border-color: $btn-color-blue  !important;
}

.btn-chart-blue:hover,
.btn-chart-blue:active{
  background-color: $btn-color-blue-hover;
  border-color: $btn-color-blue-hover  !important;
}



.theme_table_lean_blue tbody tr td {
  background-color: $blue-normal;
  color: $text-blue !important;
  cursor: default;
}

.theme_table_lean_blue tbody tr td a {
  color: $text-blue !important;
  cursor: default;
}

.theme_table_blue tbody tr:nth-child(even) td {
  background-color: $blue-normal;
  color: $text-blue;
  border: solid 1px $blue-table-border;
  cursor: default;
}

.theme_table_blue tbody tr:nth-child(odd) td {
  background-color: $blue-light;
  color: $text-blue;
  border: solid 1px $blue-table-border;
  cursor: default;
}

.theme_table_blue  tr:nth-child(even) td {
  background-color: $blue-normal;
  color: $text-blue;
  border: solid 1px $blue-table-border;
  cursor: default;
}

.theme_table_blue tr:nth-child(odd) td {
  background-color: $blue-light;
  color: $text-blue;
  border: solid 1px $blue-table-border;
  cursor: default;
}

.theme_table_head_blue tr th {
  background-color: $blue-dark;
  border: solid 1px $btn-color-blue-hover;
  color: $text-blue-light;
  cursor: default;
}

.theme_table_head_blue tr th span:focus{
  color: $text-blue;
  cursor: default;
}


.theme_icon_blue{
  color: $text-blue;
}

.theme_border_blue{
  border-color: $text-blue !important;
}

.theme_set_border_blue{
  stroke: $blue-dark;

}



.theme_text_compagny_blue{
  stroke: $text-blue;
  fill: $text-blue;
  color: $text-blue;
}

.theme_compagny_stroke_blue .rd3t-link{
  color: $text-blue;
  stroke: $text-blue !important;
  background-color: $text-blue;
}










/* Theme vert */

$green-light: #7fa486;
$green-normal: #6f8f75;
$green-dark: #4f6654;

$text-white:#fff ;
$text-green: #cfe6d3;
$text-green-light:#cdf6d4;
$text-green-red:#bd4854;
$text-green-yellow:#bb9c3f;
$text-green-light-cyan:#c3ebea;

$green-page-link :#7fa486;
$green-page-item: #8fb897;

$link-green : #6b816f;

$green-light-active: #97c19f ;
$green-cyan: #37BFBB ;
$green-red: #c7424f;
$green-green: #3f9854;

$green-red-hover:#8f363f;

$btn-color-green:#2e3d32;
$btn-color-green-dark:#466e85;
$btn-color-green-hover:#394f3f;
$btn-green-lean-hover: #394f3f;
$btn-green-green: #3a7948;
$btn-green-green-hover:#368247;
$btn-green-yellow-hover: #3c3c3c;
$btn-green-yellow: #caa843;

$btn-list-green:#a5c6ab;

$green-table-border:#86ac8d;

$green-calendar-cell-disabled: #86ac8d;
$green-calendar-header : #95c19d;






.theme_green_light{
  background-color: $green-light;
  color: $text-green;
  border:none;
}

.theme_green_light:hover{
  background-color: $green-light;
}

.theme_green_light:active{
  background-color: $green-light-active !important;
}


.theme_slide_green_light{
  background-color: $green-light;
  color: $text-green;
  border:none;
}

.theme_green_normal{
  background-color: $green-normal !important;
  color: $text-green !important;
  border:none;
}

.theme_green_normal .btn-close{
  background-color: $text-green;
  color: $text-green;
}

.theme_green_normal .rct-icon{
  color: $text-green;
}

.theme_green_normal .form-check-input:checked {
  color: $text-green !important;
  background-color: $green-cyan !important;
  border-color: $green-cyan !important;
}

.theme_green_normal .form-control,
.theme_green_normal .form-select,
.theme_green_normal .form-multi-select,
.theme_green_normal .form-multi-select-tag,
.theme_green_normal .form-multi-select-search,
.theme_green_normal .form-multi-select-options,
.theme_green_normal .form-control::placeholder,
.theme_green_normal .form-select::placeholder,
.theme_green_normal .form-multi-select::placeholder{
  color: $text-green !important;
  background-color: $green-dark !important;
  border-color: $green-normal !important;
}

.theme_green_normal .form-range{
  background-color: $green-normal !important;
  color: $text-green !important;
  border:none;
}

.theme_green_normal input{
  color: $text-green !important;
  background-color: $green-dark !important;
  border-color: $green-normal !important;
}

.theme_green_normal .form-control:disabled,
.theme_green_normal .form-select:disabled,
.theme_green_normal .form-multi-select:disabled,
.theme_green_normal .form-multi-select-tag:disabled,
.theme_green_normal .form-multi-select-search:disabled,
.theme_green_normal .form-multi-select-options:disabled,
.theme_green_normal .form-multi-select.disabled,
.theme_green_normal textarea:disabled::placeholder,
.theme_green_normal .form-control:disabled::placeholder
{
  color: $text-green !important;
  background-color: $green-light !important;
  border-color: $green-normal !important;
}

.theme_green_normal input:disabled{
  color: $text-green !important;
  background-color: $green-light !important;
  border-color: $green-normal !important;
}

.theme_green_normal .form-multi-select-dropdown{
  border-color: $green-light;
}

.theme_green_normal .form-multi-select-option.form-multi-selected::before,
.theme_green_normal .form-multi-select-option.form-multi-select-option-with-checkbox::before{
  background-color: $green-dark !important;
  border-color: $green-dark  !important;
}

.theme_green_normal .calendar-header-cell-inner,
.theme_green_normal .calendar-cell-inner,
.theme_green_normal .input-group-text,
.theme_green_normal .dropdown-menu{
  color: $text-green !important;
  background-color: $green-dark !important;
  border-color: $green-normal !important;
}

.theme_green_normal .calendar-cell.disabled .calendar-cell-inner{
  color: $green-calendar-cell-disabled !important;
}

.theme_green_normal .calendar-cell-inner:active,
.theme_green_normal .calendar-cell-inner:hover{
  color: $green-dark !important;
  background-color: $green-light !important;
}

.theme_green_normal .calendar-nav ,
.theme_green_normal .calendar-nav .btn span,
.theme_green_normal .calendar-nav .btn .btn-transparent .calendar-nav-icon-double-next,
.theme_green_normal .picker-footer

{
  color: $green-normal !important;
  background-color: $text-green !important;
  border-color: $green-normal !important;
}

.theme_green_normal .picker-footer button{
  color: $text-green !important;
  background-color: $green-dark !important;
  border-color: $green-normal !important;
}



.theme_green_normal .form-multi-select-option{
  color: $text-green !important;
  background-color: $green-light !important;
  border-color: $green-normal !important;
  margin: 5px 0px;
}

.theme_green_normal .form-multi-select button{

  color: $text-green !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $green-dark !important; /* couleur de fond de l'input lorsqu'il est en focus ou actif */
  border-color: $green-dark  !important;
}


.theme_green_normal .pagination .page-item:disabled a,
.theme_green_normal .pagination .page-item a,
.theme_board_green_normal .pagination .page-item:disabled a,
.theme_board_green_normal .pagination .page-item a{
  background-color: $green-light !important;
  color: $text-green !important;
  border:1px solid $green-light;
}

.theme_green_normal .page-link,
.theme_board_green_normal .page-link{
  background-color: $green-page-link !important;
  color: $text-green !important;
  border:1px solid $green-page-link;
}


.theme_green_dark{
  background-color: $green-dark !important;
  color: $text-green;
  border:none;
}

.theme_green_dark .accordion-header .accordion-button{
  background-color: $green-light;
  color: $text-green;
  border:none;
  box-shadow: none;
}


.theme_green_dark .dropdown-item:hover{
  background-color: $green-normal;
  color: $text-green;
  cursor: pointer;

}

.theme_green_dark::placeholder{
  color: $text-green;
}

.theme_green_dark input:focus,
.theme_green_dark input:active {
  color: $text-green !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $green-dark !important;
}

.theme_green_dark:focus,
.theme_green_dark:active {
  color: $text-green !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $green-dark !important; /* couleur de fond de l'input lorsqu'il est en focus ou actif */
}

.theme_navbar_green{
  background-color: $green-dark !important;
  color: $text-green ;
}

.theme_lean_green_dark{
  color: $text-green;
  background-color: $green-dark !important;
  border: $green-dark solid 1px;
}

.theme_lean_green_dark:hover{
  color: $text-green;
  background-color: $btn-green-lean-hover !important;
  border: $btn-green-lean-hover solid 1px;
}


.theme_lean_green_dark:active{
  color: $text-green;
  background-color: $btn-green-lean-hover !important;
  border: $btn-green-lean-hover solid 1px !important;
}

.theme_slideshow_green_dark{
  background-color: $green-dark !important;
  padding-top : 10px;
  color: $text-green;
  border:none;
}


.theme_slideshow_green_normal{
  background-color: $green-normal !important;
  padding-top : 10px;
  color: $text-green;
  border:none;
}

 .hori-timeline .events{
  border-top:none;
}

.theme_green_red{
  background-color: $green-red;
  border: 1px solid $green-red;
}

.theme_green_green{
  background-color: $green-green;
  border:none;
}


.theme_board_green_normal{
  background-color: $green-dark !important;
  color:$text-green;
  border-color: $green-dark !important;

}

.theme_board_green_normal input,
.theme_board_green_normal input::placeholder,
.theme_board_green_normal input:focus,
.theme_board_green_normal .form-select
{
  background-color: $green-normal !important;
  color:$text-green;
  border-color: $green-light !important;

}

.theme_board_green_light{
  background-color: $green-light;
  color:$text-green;
  border-color: $green-light !important;
}

.theme_board_green_dark{
  background-color: $green-dark !important;
  color:$text-green;
  border-color: $green-dark !important;
}


.theme_green_dark .page-item a,
.theme_green_normal .page-item a{
  background-color: $green-light !important;
  color:$text-green;
  border-color: $green-light !important;
}


.theme_green_dark .page-item span,
.theme_green_normal .page-item span{
  background-color: $green-page-item !important;
  color:$text-green;
  border-color: $green-page-item !important;
}



.theme_text_noddle_green{
  color: $text-green !important;
}


.theme_calendar_green_light,
.theme_calendar_green_light button
{
  background-color: $green-light !important;
  color: $text-green !important;
  border:none;
  box-shadow: none !important;
}

.theme_calendar_green_normal{
  background-color: $green-normal;
  color: $text-green;
  border:none;
}

.theme_calendar_green_dark{
  background-color: $green-dark;
  color: $text-green;
  border:none;
}

.theme_calendar_green_dark .fc-daygrid-day-number{
  color: $text-green;
}

.theme_calendar_green_dark .fc-button,
.theme_calendar_green_dark .form-check-input:checked,
.theme_calendar_green_dark .form-check-input::before
{
  background-color: $green-cyan !important;
  border-color: $green-cyan !important;
}

.theme_calendar_green_dark .fc-day-today{
  background-color: $green-normal !important;
}

.theme_calendar_green_dark .fc-col-header-cell,
.theme_calendar_green_dark .fc-col-header-cell a
{
  color: $text-green;
  background-color: $green-calendar-header !important;
  border-color: $green-calendar-header !important;
}

.theme_calendar_green_dark .fc-daygrid-day-frame:hover,
.theme_calendar_green_dark .fc-daygrid-day-frame:active{
  background-color: $green-light;
}

.theme_user_checkbox_green{
  background-color: $green-dark !important;
  border-color: $green-dark !important;
}














.theme_text_green{
  color: $text-green !important;
}

.theme_text_green:disabled{
  color: $text-green !important;
}

.theme_text_green:hover{
  color: $text-green-light;

}

.theme_text_green:active{
  color: $text-green;
}

.theme_text_red_green{
  color: $text-green-red !important;
}

.theme_text_green_green{
  color: $green-green !important;
}

.theme_text_yellow_green{
  color: $text-green-yellow !important;
}

.theme_text_cyan_green{
  color: $text-green-light-cyan !important;
}

.theme_home_text_green{
  color: $text-green;
}

.theme_text_light_green{
  color: $text-green-light !important;
}

.theme_link_green{
  color: $link-green !important;
}

.theme_link_green a{
  color: $text-green;
}

.theme_link_green span{
  color: $text-green;
}

.theme_text_badge_green{
  color: $text-green !important;
}






.bg-gradient-green {
  --bs-bg-opacity: 1;
  background-image: linear-gradient(90deg, $green-dark, $green-normal);
}








.btn-color-green{
  background-color: $btn-color-green !important;
  border: 1px solid $btn-color-green !important;
}

.btn-color-green:hover,
.btn-color-green:active{
  background-color: $btn-color-green-hover !important;
}

.btn-badge-open-green{
  background-color: $green-cyan;
  border: none;
}

.btn-badge-closed-green{
  background-color: $btn-color-green;
  border: none;
}

.btn-badge-archived-green{
  background-color: $btn-color-green;
  border: none;
}

.btn-badge-yellow-green,
.btn-badge-yellow-green:disabled
{
  background-color: $green-dark;
  border: 1px solid $green-dark;
}

.btn-badge-yellow-green:hover,
.btn-badge-yellow-green:active{
  background-color: $btn-green-yellow-hover !important;
  border: 1px solid $btn-green-yellow-hover;
}


.btn-color-red-green{
  background-color: $text-green-red;
  border: 1px solid $text-green-red;
}

.btn-color-red-green:hover,
.btn-color-red-green:active
{
  background-color: $green-red-hover !important;
  border: 1px solid $green-red-hover !important;
}

.btn-green-green,
.btn-green-green:disabled
{
  background-color: $btn-green-green !important;
  border: 1px solid $btn-green-green !important;
}

.btn-green-green:hover,
.btn-green-green:active{
  background-color: $btn-green-green-hover !important;
}

.btn-yellow-green,
.btn-yellow-green:disabled
{
  background-color: $text-green-yellow !important;
  border: none;
}

.btn-yellow-green:hover,
.btn-yellow-green:active{
  background-color: $btn-green-yellow !important ;
}

.btn-list-color-green .nav-link{
  background-color: $btn-color-green !important;
  border-radius: 5px !important;
  color: $text-green;
}

.btn-list-color-green .nav-link:hover{
  background-color: $btn-list-green !important;
}

.btn-board-green label,
.btn-board-green:hover label
{
  color: $text-green !important;
  border-color: $btn-color-green !important;
}

.btn-board-green .btn-check:checked + .btn{
  background-color: $btn-color-green !important;
}


.btn-outline-green,
.btn-outline-green:hover{
  color: $text-green !important;
  border-color: $btn-color-green !important;
}

.theme_green_dark .btn-check:checked + .btn{
  background-color: $btn-color-green !important;
  color: $text-green-light!important;
}

.btn-color-green-dark{
  background-color: $green-dark !important;
  border: 1px solid $green-dark !important;
  color: $text-white !important;
}

.btn-color-green-dark:hover,
.btn-color-green-dark:active{
  background-color: $btn-color-green-dark !important;
}

.btn-chart-green{
  background-color: $btn-color-green;
  border-color: $btn-color-green  !important;
}

.btn-chart-green:hover,
.btn-chart-green:active{
  background-color: $btn-color-green-hover;
  border-color: $btn-color-green-hover  !important;
}



.theme_table_lean_green tbody tr td {
  background-color: $green-normal;
  color: $text-green !important;
  cursor: default;
}

.theme_table_lean_green tbody tr td a {
  color: $text-green !important;
  cursor: default;
}

.theme_table_green tbody tr:nth-child(even) td {
  background-color: $green-normal;
  color: $text-green;
  border: solid 1px $green-table-border;
  cursor: default;
}

.theme_table_green tbody tr:nth-child(odd) td {
  background-color: $green-light;
  color: $text-green;
  border: solid 1px $green-table-border;
  cursor: default;
}

.theme_table_green  tr:nth-child(even) td {
  background-color: $green-normal;
  color: $text-green;
  border: solid 1px $green-table-border;
  cursor: default;
}

.theme_table_green tr:nth-child(odd) td {
  background-color: $green-light;
  color: $text-green;
  border: solid 1px $green-table-border;
  cursor: default;
}

.theme_table_head_green tr th {
  background-color: $green-dark;
  border: solid 1px $btn-color-green-hover;
  color: $text-green-light;
  cursor: default;
}

.theme_table_head_green tr th span:focus{
  color: $text-green;
  cursor: default;
}


.theme_icon_green{
  color: $text-green;
}

.theme_border_green{
  border-color: $text-green  !important;
}

.theme_set_border_green{
  stroke: $green-dark;

}


.theme_text_compagny_green{
  stroke: $text-green;
  fill: $text-green;
  color: $text-green;
}

.theme_compagny_stroke_green .rd3t-link{
  color: $text-green;
  stroke: $text-green !important;
  background-color: $text-green;
}















/* Theme jaune */


$yellow-light: #ffe5c1;
$yellow-normal: #ffd293;
$yellow-dark: #FFBF66;

$text-white:#fff ;
$text-yellow: #322614;
$text-yellow-light:#8a7048;
$text-yellow-red:#bd4854;
$text-yellow-yellow:#bb9c3f;
$text-yellow-light-cyan:#c3ebea;

$yellow-page-link :#d9a65d;
$yellow-page-item: #f8bf70;

$link-yellow : #cc9f61;

$yellow-light-active: #e6bd83 ;
$yellow-cyan: #37BFBB ;
$yellow-red: #c7424f;
$yellow-green: #3f9854;

$yellow-red-hover:#8f363f;

$btn-color-yellow:#b08548;
$btn-color-yellow-dark:#926e3b;
$btn-color-yellow-hover:#ba8d4f;
$btn-yellow-lean-hover: #ba8d4f;
$btn-yellow-green: #3a7948;
$btn-yellow-green-hover:#368247;
$btn-yellow-yellow-hover: #3c3c3c;
$btn-yellow-yellow: #caa843;

$btn-list-yellow:#ffd293;

$yellow-table-border:#ddc097;

$yellow-calendar-cell-disabled: #ffd293;
$yellow-calendar-header : #ffd293;






.theme_yellow_light{
  background-color: $yellow-light;
  color: $text-yellow;
  border:none;
}

.theme_yellow_light:hover{
  background-color: $yellow-light;
}

.theme_yellow_light:active{
  background-color: $yellow-light-active !important;
}


.theme_slide_yellow_light{
  background-color: $yellow-light;
  color: $text-yellow;
  border:none;
}

.theme_yellow_normal{
  background-color: $yellow-normal !important;
  color: $text-yellow !important;
  border:none;
}

.theme_yellow_normal .btn-close{
  background-color: $text-yellow;
  color: $text-yellow;
}

.theme_yellow_normal .rct-icon{
  color: $text-yellow;
}

.theme_yellow_normal .form-check-input:checked {
  color: $text-yellow !important;
  background-color: $yellow-cyan !important;
  border-color: $yellow-cyan !important;
}

.theme_yellow_normal .form-control,
.theme_yellow_normal .form-select,
.theme_yellow_normal .form-multi-select,
.theme_yellow_normal .form-multi-select-tag,
.theme_yellow_normal .form-multi-select-search,
.theme_yellow_normal .form-multi-select-options,
.theme_yellow_normal .form-control::placeholder,
.theme_yellow_normal .form-select::placeholder,
.theme_yellow_normal .form-multi-select::placeholder{
  color: $text-yellow !important;
  background-color: $yellow-dark !important;
  border-color: $yellow-normal !important;
}

.theme_yellow_normal .form-range{
  background-color: $yellow-normal !important;
  color: $text-yellow !important;
  border:none;
}

.theme_yellow_normal input{
  color: $text-yellow !important;
  background-color: $yellow-dark !important;
  border-color: $yellow-normal !important;
}

.theme_yellow_normal .form-control:disabled,
.theme_yellow_normal .form-select:disabled,
.theme_yellow_normal .form-multi-select:disabled,
.theme_yellow_normal .form-multi-select-tag:disabled,
.theme_yellow_normal .form-multi-select-search:disabled,
.theme_yellow_normal .form-multi-select-options:disabled,
.theme_yellow_normal .form-multi-select.disabled,
.theme_yellow_normal textarea:disabled::placeholder,
.theme_yellow_normal .form-control:disabled::placeholder
{
  color: $text-yellow !important;
  background-color: $yellow-light !important;
  border-color: $yellow-normal !important;
}

.theme_yellow_normal input:disabled{
  color: $text-yellow !important;
  background-color: $yellow-light !important;
  border-color: $yellow-normal !important;
}

.theme_yellow_normal .form-multi-select-dropdown{
  border-color: $yellow-light  !important;
}

.theme_yellow_normal .form-multi-select-option.form-multi-selected::before,
.theme_yellow_normal .form-multi-select-option.form-multi-select-option-with-checkbox::before{
  background-color: $yellow-dark !important;
  border-color: $yellow-dark  !important;
}

.theme_yellow_normal .calendar-header-cell-inner,
.theme_yellow_normal .calendar-cell-inner,
.theme_yellow_normal .input-group-text,
.theme_yellow_normal .dropdown-menu{
  color: $text-yellow !important;
  background-color: $yellow-dark !important;
  border-color: $yellow-normal !important;
}

.theme_yellow_normal .calendar-cell.disabled .calendar-cell-inner{
  color: $yellow-calendar-cell-disabled !important;
}

.theme_yellow_normal .calendar-cell-inner:active,
.theme_yellow_normal .calendar-cell-inner:hover{
  color: $yellow-dark !important;
  background-color: $yellow-light !important;
}

.theme_yellow_normal .calendar-nav ,
.theme_yellow_normal .calendar-nav .btn span,
.theme_yellow_normal .calendar-nav .btn .btn-transparent .calendar-nav-icon-double-next,
.theme_yellow_normal .picker-footer

{
  color: $yellow-normal !important;
  background-color: $yellow-light !important;
  border-color: $yellow-normal !important;
}

.theme_yellow_normal .picker-footer button{
  color: $text-yellow !important;
  background-color: $yellow-dark !important;
  border-color: $yellow-normal !important;
}



.theme_yellow_normal .form-multi-select-option{
  color: $text-yellow !important;
  background-color: $yellow-light !important;
  border-color: $yellow-normal !important;
  margin: 5px 0px;
}

.theme_yellow_normal .form-multi-select button{

  color: $text-yellow !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $yellow-dark !important; /* couleur de fond de l'input lorsqu'il est en focus ou actif */
  border-color: $yellow-dark  !important;
}


.theme_yellow_normal .pagination .page-item:disabled a,
.theme_yellow_normal .pagination .page-item a,
.theme_board_yellow_normal .pagination .page-item:disabled a,
.theme_board_yellow_normal .pagination .page-item a{
  background-color: $yellow-light !important;
  color: $text-yellow !important;
  border:1px solid $yellow-light;
}

.theme_yellow_normal .page-link,
.theme_board_yellow_normal .page-link{
  background-color: $yellow-page-link !important;
  color: $text-yellow !important;
  border:1px solid $yellow-page-link;
}


.theme_yellow_dark{
  background-color: $yellow-dark !important;
  color: $text-yellow;
  border:none;
}

.theme_yellow_dark .accordion-header .accordion-button{
  background-color: $yellow-light;
  color: $text-yellow;
  border:none;
  box-shadow: none;
}


.theme_yellow_dark .dropdown-item:hover{
  background-color: $yellow-normal;
  color: $text-yellow;
  cursor: pointer;

}

.theme_yellow_dark::placeholder{
  color: $text-yellow;
}

.theme_yellow_dark input:focus,
.theme_yellow_dark input:active {
  color: $text-yellow !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $yellow-dark !important;
}

.theme_yellow_dark:focus,
.theme_yellow_dark:active {
  color: $text-yellow !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $yellow-dark !important; /* couleur de fond de l'input lorsqu'il est en focus ou actif */
}

.theme_navbar_yellow{
  background-color: $yellow-dark !important;
  color: $text-yellow ;
}

.theme_lean_yellow_dark{
  color: $text-yellow;
  background-color: $yellow-dark !important;
  border: $yellow-dark solid 1px;
}

.theme_lean_yellow_dark:hover{
  color: $text-yellow;
  background-color: $btn-yellow-lean-hover !important;
  border: $btn-yellow-lean-hover solid 1px;
}


.theme_lean_yellow_dark:active{
  color: $text-yellow;
  background-color: $btn-yellow-lean-hover !important;
  border: $btn-yellow-lean-hover solid 1px !important;
}

.theme_slideshow_yellow_dark{
  background-color: $yellow-dark !important;
  padding-top : 10px;
  color: $text-yellow;
  border:none;
}


.theme_slideshow_yellow_normal{
  background-color: $yellow-normal !important;
  padding-top : 10px;
  color: $text-yellow;
  border:none;
}

 .hori-timeline .events{
  border-top:none;
}

.theme_yellow_red{
  background-color: $yellow-red;
  border: 1px solid $yellow-red;
}

.theme_yellow_green{
  background-color: $yellow-green;
  border:none;
}


.theme_board_yellow_normal{
  background-color: $yellow-dark !important;
  color:$text-yellow;
  border-color: $yellow-dark !important;

}

.theme_board_yellow_normal input,
.theme_board_yellow_normal input::placeholder,
.theme_board_yellow_normal input:focus,
.theme_board_yellow_normal .form-select
{
  background-color: $yellow-normal !important;
  color:$text-yellow;
  border-color: $yellow-light !important;

}

.theme_board_yellow_light{
  background-color: $yellow-light;
  color:$text-yellow;
  border-color: $yellow-light !important;
}

.theme_board_yellow_dark{
  background-color: $yellow-dark !important;
  color:$text-yellow;
  border-color: $yellow-dark !important;
}


.theme_yellow_dark .page-item a,
.theme_yellow_normal .page-item a{
  background-color: $yellow-light !important;
  color:$text-yellow;
  border-color: $yellow-light !important;
}


.theme_yellow_dark .page-item span,
.theme_yellow_normal .page-item span{
  background-color: $yellow-page-item !important;
  color:$text-yellow;
  border-color: $yellow-page-item !important;
}



.theme_text_noddle_yellow{
  color: $text-yellow !important;
}


.theme_calendar_yellow_light,
.theme_calendar_yellow_light button
{
  background-color: $yellow-light !important;
  color: $text-yellow !important;
  border:none;
  box-shadow: none !important;
}

.theme_calendar_yellow_normal{
  background-color: $yellow-normal;
  color: $text-yellow;
  border:none;
}

.theme_calendar_yellow_dark{
  background-color: $yellow-dark;
  color: $text-yellow;
  border:none;
}

.theme_calendar_yellow_dark .fc-daygrid-day-number{
  color: $text-yellow;
}

.theme_calendar_yellow_dark .fc-button,
.theme_calendar_yellow_dark .form-check-input:checked,
.theme_calendar_yellow_dark .form-check-input::before
{
  background-color: $yellow-cyan !important;
  border-color: $yellow-cyan !important;
}

.theme_calendar_yellow_dark .fc-day-today{
  background-color: $yellow-normal !important;
}

.theme_calendar_yellow_dark .fc-col-header-cell,
.theme_calendar_yellow_dark .fc-col-header-cell a
{
  color: $text-yellow;
  background-color: $yellow-calendar-header !important;
  border-color: $yellow-calendar-header !important;
}

.theme_calendar_yellow_dark .fc-daygrid-day-frame:hover,
.theme_calendar_yellow_dark .fc-daygrid-day-frame:active{
  background-color: $yellow-light;
}

.theme_user_checkbox_yellow{
  background-color: $yellow-dark !important;
  border-color: $yellow-dark !important;
}














.theme_text_yellow{
  color: $text-yellow !important;
}

.theme_text_yellow:disabled{
  color: $text-yellow !important;
}

.theme_text_yellow:hover{
  color: $text-yellow-light;

}

.theme_text_yellow:active{
  color: $text-yellow;
}

.theme_text_red_yellow{
  color: $text-yellow-red !important;
}

.theme_text_green_yellow{
  color: $yellow-green !important;
}

.theme_text_yellow_yellow{
  color: $text-yellow-yellow !important;
}

.theme_text_cyan_yellow{
  color: $text-yellow-light-cyan !important;
}

.theme_home_text_yellow{
  color: $text-yellow;
}

.theme_text_light_yellow{
  color: $text-yellow-light !important;
}

.theme_link_yellow{
  color: $link-yellow !important;
}

.theme_link_yellow a{
  color: $text-yellow;
}

.theme_link_yellow span{
  color: $text-yellow;
}

.theme_text_badge_yellow{
  color: $text-yellow !important;
}






.bg-gradient-yellow {
  --bs-bg-opacity: 1;
  background-image: linear-gradient(90deg, $yellow-dark, $yellow-normal);
}








.btn-color-yellow{
  background-color: $btn-color-yellow !important;
  border: 1px solid $btn-color-yellow !important;
}

.btn-color-yellow:hover,
.btn-color-yellow:active{
  background-color: $btn-color-yellow-hover !important;
}

.btn-badge-open-yellow{
  background-color: $yellow-cyan;
  border: none;
}

.btn-badge-closed-yellow{
  background-color: $btn-color-yellow;
  border: none;
}

.btn-badge-archived-yellow{
  background-color: $btn-color-yellow;
  border: none;
}

.btn-badge-yellow-yellow,
.btn-badge-yellow-yellow:disabled
{
  background-color: $yellow-dark;
  border: 1px solid $yellow-dark;
}

.btn-badge-yellow-yellow:hover,
.btn-badge-yellow-yellow:active{
  background-color: $btn-yellow-yellow-hover !important;
  border: 1px solid $btn-yellow-yellow-hover;
}


.btn-color-red-yellow{
  background-color: $text-yellow-red;
  border: 1px solid $text-yellow-red;
}

.btn-color-red-yellow:hover,
.btn-color-red-yellow:active
{
  background-color: $yellow-red-hover !important;
  border: 1px solid $yellow-red-hover !important;
}

.btn-green-yellow,
.btn-green-yellow:disabled
{
  background-color: $btn-yellow-green !important;
  border: 1px solid $btn-yellow-green !important;
}

.btn-green-yellow:hover,
.btn-green-yellow:active{
  background-color: $btn-yellow-green-hover !important;
}

.btn-yellow-yellow,
.btn-yellow-yellow:disabled
{
  background-color: $text-yellow-yellow !important;
  border: none;
}

.btn-yellow-yellow:hover,
.btn-yellow-yellow:active{
  background-color: $btn-yellow-yellow !important ;
}

.btn-list-color-yellow .nav-link{
  background-color: $btn-color-yellow !important;
  border-radius: 5px !important;
  color: $text-yellow;
}

.btn-list-color-yellow .nav-link:hover{
  background-color: $btn-list-yellow !important;
}

.btn-board-yellow label,
.btn-board-yellow:hover label
{
  color: $text-yellow !important;
  border-color: $btn-color-yellow !important;
}

.btn-board-yellow .btn-check:checked + .btn{
  background-color: $btn-color-yellow !important;
}


.btn-outline-yellow,
.btn-outline-yellow{
  color: $text-yellow-light !important;
  border-color: $btn-color-yellow !important;
}

.theme_yellow_dark .btn-check:checked + .btn{
  background-color: $btn-color-yellow !important;

  color: $text-yellow !important;
}

.btn-color-yellow-dark{
  background-color: $yellow-dark !important;
  border: 1px solid $yellow-dark !important;
  color: $text-white !important;
}

.btn-color-yellow-dark:hover,
.btn-color-yellow-dark:active{
  background-color: $btn-color-yellow-dark !important;
}

.btn-chart-yellow{
  background-color: $btn-color-yellow;
  border-color: $btn-color-yellow  !important;
}

.btn-chart-yellow:hover,
.btn-chart-yellow:active{
  background-color: $btn-color-yellow-hover;
  border-color: $btn-color-yellow-hover  !important;
}



.theme_table_lean_yellow tbody tr td {
  background-color: $yellow-normal;
  color: $text-yellow !important;
  cursor: default;
}

.theme_table_lean_yellow tbody tr td a {
  color: $text-yellow !important;
  cursor: default;
}

.theme_table_yellow tbody tr:nth-child(even) td {
  background-color: $yellow-normal;
  color: $text-yellow;
  border: solid 1px $yellow-table-border;
  cursor: default;
}

.theme_table_yellow tbody tr:nth-child(odd) td {
  background-color: $yellow-light;
  color: $text-yellow;
  border: solid 1px $yellow-table-border;
  cursor: default;
}

.theme_table_yellow  tr:nth-child(even) td {
  background-color: $yellow-normal;
  color: $text-yellow;
  border: solid 1px $yellow-table-border;
  cursor: default;
}

.theme_table_yellow tr:nth-child(odd) td {
  background-color: $yellow-light;
  color: $text-yellow;
  border: solid 1px $yellow-table-border;
  cursor: default;
}

.theme_table_head_yellow tr th {
  background-color: $yellow-dark;
  border: solid 1px $btn-color-yellow-hover;
  color: $text-yellow-light;
  cursor: default;
}

.theme_table_head_yellow tr th span:focus{
  color: $text-yellow;
  cursor: default;
}


.theme_icon_yellow{
  color: $text-yellow;
}

.theme_border_yellow{
  border-color: $text-yellow !important;
}

.theme_set_border_yellow{
  stroke: $yellow-dark;

}

.theme_text_compagny_yellow{
  stroke: $text-yellow;
  fill: $text-yellow;
  color: $text-yellow;
}

.theme_compagny_stroke_yellow .rd3t-link{
  color: $text-yellow;
  stroke: $text-yellow !important;
  background-color: $text-yellow;
}





















/* Theme orange */


$orange-light: #f9caa6;
$orange-normal: #f7b988;
$orange-dark: #F5A86B;

$text-white:#fff ;
$text-orange: #18100a;
$text-orange-light:#432610;
$text-orange-red:#bd4854;
$text-orange-yellow:#bb9c3f;
$text-orange-light-cyan:#c3ebea;

$orange-page-link :#edad79;
$orange-page-item: #ec9651;

$link-orange : #c56d2a;

$orange-light-active: #f5b07c ;
$orange-cyan: #37BFBB ;
$orange-red: #c7424f;
$orange-green: #3f9854;

$orange-red-hover:#8f363f;

$btn-color-orange:#e4873c;
$btn-color-orange-dark:#965c2c;
$btn-color-orange-hover:#e48c45;
$btn-orange-lean-hover: #e48c45;
$btn-orange-green: #3a7948;
$btn-orange-green-hover:#368247;
$btn-orange-yellow-hover: #3c3c3c;
$btn-orange-yellow: #caa843;

$btn-list-orange:#eeb081;

$orange-table-border:#e8ba97;

$orange-calendar-cell-disabled: #e7b58f;
$orange-calendar-header : #f09753;






.theme_orange_light{
  background-color: $orange-light;
  color: $text-orange;
  border:none;
}

.theme_orange_light:hover{
  background-color: $orange-light;
}

.theme_orange_light:active{
  background-color: $orange-light-active !important;
}


.theme_slide_orange_light{
  background-color: $orange-light;
  color: $text-orange;
  border:none;
}

.theme_orange_normal{
  background-color: $orange-normal !important;
  color: $text-orange !important;
  border:none;
}

.theme_orange_normal .btn-close{
  background-color: $text-orange;
  color: $text-orange;
}

.theme_orange_normal .rct-icon{
  color: $text-orange;
}

.theme_orange_normal .form-check-input:checked {
  color: $text-orange !important;
  background-color: $orange-cyan !important;
  border-color: $orange-cyan !important;
}

.theme_orange_normal .form-control,
.theme_orange_normal .form-select,
.theme_orange_normal .form-multi-select,
.theme_orange_normal .form-multi-select-tag,
.theme_orange_normal .form-multi-select-search,
.theme_orange_normal .form-multi-select-options,
.theme_orange_normal .form-control::placeholder,
.theme_orange_normal .form-select::placeholder,
.theme_orange_normal .form-multi-select::placeholder{
  color: $text-orange !important;
  background-color: $orange-dark !important;
  border-color: $orange-normal !important;
}

.theme_orange_normal .form-range{
  background-color: $orange-normal !important;
  color: $text-orange !important;
  border:none;
}

.theme_orange_normal input{
  color: $text-orange !important;
  background-color: $orange-dark !important;
  border-color: $orange-normal !important;
}

.theme_orange_normal .form-control:disabled,
.theme_orange_normal .form-select:disabled,
.theme_orange_normal .form-multi-select:disabled,
.theme_orange_normal .form-multi-select-tag:disabled,
.theme_orange_normal .form-multi-select-search:disabled,
.theme_orange_normal .form-multi-select-options:disabled,
.theme_orange_normal .form-multi-select.disabled,
.theme_orange_normal textarea:disabled::placeholder,
.theme_orange_normal .form-control:disabled::placeholder
{
  color: $text-orange !important;
  background-color: $orange-light !important;
  border-color: $orange-normal !important;
}

.theme_orange_normal input:disabled{
  color: $text-orange !important;
  background-color: $orange-light !important;
  border-color: $orange-normal !important;
}

.theme_orange_normal .form-multi-select-dropdown{
  border-color: $orange-light  !important;
}

.theme_orange_normal .form-multi-select-option.form-multi-selected::before,
.theme_orange_normal .form-multi-select-option.form-multi-select-option-with-checkbox::before{
  background-color: $orange-dark !important;
  border-color: $orange-dark  !important;
}

.theme_orange_normal .calendar-header-cell-inner,
.theme_orange_normal .calendar-cell-inner,
.theme_orange_normal .input-group-text,
.theme_orange_normal .dropdown-menu{
  color: $text-orange !important;
  background-color: $orange-dark !important;
  border-color: $orange-normal !important;
}

.theme_orange_normal .calendar-cell.disabled .calendar-cell-inner{
  color: $orange-calendar-cell-disabled !important;
}

.theme_orange_normal .calendar-cell-inner:active,
.theme_orange_normal .calendar-cell-inner:hover{
  color: $orange-dark !important;
  background-color: $orange-light !important;
}

.theme_orange_normal .calendar-nav ,
.theme_orange_normal .calendar-nav .btn span,
.theme_orange_normal .calendar-nav .btn .btn-transparent .calendar-nav-icon-double-next,
.theme_orange_normal .picker-footer

{
  color: $orange-normal !important;
  background-color: $orange-light !important;
  border-color: $orange-normal !important;
}

.theme_orange_normal .picker-footer button{
  color: $text-orange !important;
  background-color: $orange-dark !important;
  border-color: $orange-normal !important;
}



.theme_orange_normal .form-multi-select-option{
  color: $text-orange !important;
  background-color: $orange-light !important;
  border-color: $orange-normal !important;
  margin: 5px 0px;
}

.theme_orange_normal .form-multi-select button{

  color: $text-orange !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $orange-dark !important; /* couleur de fond de l'input lorsqu'il est en focus ou actif */
  border-color: $orange-dark  !important;
}


.theme_orange_normal .pagination .page-item:disabled a,
.theme_orange_normal .pagination .page-item a,
.theme_board_orange_normal .pagination .page-item:disabled a,
.theme_board_orange_normal .pagination .page-item a{
  background-color: $orange-light !important;
  color: $text-orange !important;
  border:1px solid $orange-light;
}

.theme_orange_normal .page-link,
.theme_board_orange_normal .page-link{
  background-color: $orange-page-link !important;
  color: $text-orange !important;
  border:1px solid $orange-page-link;
}


.theme_orange_dark{
  background-color: $orange-dark !important;
  color: $text-orange;
  border:none;
}

.theme_orange_dark .accordion-header .accordion-button{
  background-color: $orange-light;
  color: $text-orange;
  border:none;
  box-shadow: none;
}


.theme_orange_dark .dropdown-item:hover{
  background-color: $orange-normal;
  color: $text-orange;
  cursor: pointer;

}

.theme_orange_dark::placeholder{
  color: $text-orange;
}

.theme_orange_dark input:focus,
.theme_orange_dark input:active {
  color: $text-orange !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $orange-dark !important;
}

.theme_orange_dark:focus,
.theme_orange_dark:active {
  color: $text-orange !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $orange-dark !important; /* couleur de fond de l'input lorsqu'il est en focus ou actif */
}

.theme_navbar_orange{
  background-color: $orange-dark !important;
  color: $text-orange ;
}

.theme_lean_orange_dark{
  color: $text-orange;
  background-color: $orange-dark !important;
  border: $orange-dark solid 1px;
}

.theme_lean_orange_dark:hover{
  color: $text-orange;
  background-color: $btn-orange-lean-hover !important;
  border: $btn-orange-lean-hover solid 1px;
}


.theme_lean_orange_dark:active{
  color: $text-orange;
  background-color: $btn-orange-lean-hover !important;
  border: $btn-orange-lean-hover solid 1px !important;
}

.theme_slideshow_orange_dark{
  background-color: $orange-dark !important;
  padding-top : 10px;
  color: $text-orange;
  border:none;
}


.theme_slideshow_orange_normal{
  background-color: $orange-normal !important;
  padding-top : 10px;
  color: $text-orange;
  border:none;
}

 .hori-timeline .events{
  border-top:none;
}

.theme_orange_red{
  background-color: $orange-red;
  border: 1px solid $orange-red;
}

.theme_orange_green{
  background-color: $orange-green;
  border:none;
}


.theme_board_orange_normal{
  background-color: $orange-dark !important;
  color:$text-orange;
  border-color: $orange-dark !important;

}

.theme_board_orange_normal input,
.theme_board_orange_normal input::placeholder,
.theme_board_orange_normal input:focus,
.theme_board_orange_normal .form-select
{
  background-color: $orange-normal !important;
  color:$text-orange;
  border-color: $orange-light !important;

}

.theme_board_orange_light{
  background-color: $orange-light;
  color:$text-orange;
  border-color: $orange-light !important;
}

.theme_board_orange_dark{
  background-color: $orange-dark !important;
  color:$text-orange;
  border-color: $orange-dark !important;
}


.theme_orange_dark .page-item a,
.theme_orange_normal .page-item a{
  background-color: $orange-light !important;
  color:$text-orange;
  border-color: $orange-light !important;
}


.theme_orange_dark .page-item span,
.theme_orange_normal .page-item span{
  background-color: $orange-page-item !important;
  color:$text-orange;
  border-color: $orange-page-item !important;
}



.theme_text_noddle_orange{
  color: $text-orange !important;
}


.theme_calendar_orange_light,
.theme_calendar_orange_light button
{
  background-color: $orange-light !important;
  color: $text-orange !important;
  border:none;
  box-shadow: none !important;
}

.theme_calendar_orange_normal{
  background-color: $orange-normal;
  color: $text-orange;
  border:none;
}

.theme_calendar_orange_dark{
  background-color: $orange-dark;
  color: $text-orange;
  border:none;
}

.theme_calendar_orange_dark .fc-daygrid-day-number{
  color: $text-orange;
}

.theme_calendar_orange_dark .fc-button,
.theme_calendar_orange_dark .form-check-input:checked,
.theme_calendar_orange_dark .form-check-input::before
{
  background-color: $orange-cyan !important;
  border-color: $orange-cyan !important;
}

.theme_calendar_orange_dark .fc-day-today{
  background-color: $orange-normal !important;
}

.theme_calendar_orange_dark .fc-col-header-cell,
.theme_calendar_orange_dark .fc-col-header-cell a
{
  color: $text-orange;
  background-color: $orange-calendar-header !important;
  border-color: $orange-calendar-header !important;
}

.theme_calendar_orange_dark .fc-daygrid-day-frame:hover,
.theme_calendar_orange_dark .fc-daygrid-day-frame:active{
  background-color: $orange-light;
}

.theme_user_checkbox_orange{
  background-color: $orange-dark !important;
  border-color: $orange-dark !important;
}














.theme_text_orange{
  color: $text-orange !important;
}

.theme_text_orange:disabled{
  color: $text-orange !important;
}

.theme_text_orange:hover{
  color: $text-orange-light;

}

.theme_text_orange:active{
  color: $text-orange;
}

.theme_text_red_orange{
  color: $text-orange-red !important;
}

.theme_text_green_orange{
  color: $orange-green !important;
}

.theme_text_orange_yellow{
  color: $text-orange-yellow !important;
}

.theme_text_cyan_orange{
  color: $text-orange-light-cyan !important;
}

.theme_home_text_orange{
  color: $text-orange;
}

.theme_text_light_orange{
  color: $text-orange-light !important;
}

.theme_link_orange{
  color: $link-orange !important;
}

.theme_link_orange a{
  color: $text-orange;
}

.theme_link_orange span{
  color: $text-orange;
}

.theme_text_badge_orange{
  color: $text-orange !important;
}






.bg-gradient-orange {
  --bs-bg-opacity: 1;
  background-image: linear-gradient(90deg, $orange-dark, $orange-normal);
}








.btn-color-orange{
  background-color: $btn-color-orange !important;
  border: 1px solid $btn-color-orange !important;
}

.btn-color-orange:hover,
.btn-color-orange:active{
  background-color: $btn-color-orange-hover !important;
}

.btn-badge-open-orange{
  background-color: $orange-cyan;
  border: none;
}

.btn-badge-closed-orange{
  background-color: $btn-color-orange;
  border: none;
}

.btn-badge-archived-orange{
  background-color: $btn-color-orange;
  border: none;
}

.btn-badge-orange-yellow,
.btn-badge-orange-yellow:disabled
{
  background-color: $orange-dark;
  border: 1px solid $orange-dark;
}

.btn-badge-orange-yellow:hover,
.btn-badge-orange-yellow:active{
  background-color: $btn-orange-yellow-hover !important;
  border: 1px solid $btn-orange-yellow-hover;
}


.btn-color-red-orange{
  background-color: $text-orange-red;
  border: 1px solid $text-orange-red;
}

.btn-color-red-orange:hover,
.btn-color-red-orange:active
{
  background-color: $orange-red-hover !important;
  border: 1px solid $orange-red-hover !important;
}

.btn-green-orange,
.btn-green-orange:disabled
{
  background-color: $btn-orange-green !important;
  border: 1px solid $btn-orange-green !important;
}

.btn-green-orange:hover,
.btn-green-orange:active{
  background-color: $btn-orange-green-hover !important;
}

.btn-orange-yellow,
.btn-orange-yellow:disabled
{
  background-color: $text-orange-yellow !important;
  border: none;
}

.btn-orange-yellow:hover,
.btn-orange-yellow:active{
  background-color: $btn-orange-yellow !important ;
}

.btn-list-color-orange .nav-link{
  background-color: $btn-color-orange !important;
  border-radius: 5px !important;
  color: $text-orange;
}

.btn-list-color-orange .nav-link:hover{
  background-color: $btn-list-orange !important;
}

.btn-board-orange label,
.btn-board-orange:hover label
{
  color: $text-orange !important;
  border-color: $btn-color-orange !important;
}

.btn-board-orange .btn-check:checked + .btn{
  background-color: $btn-color-orange !important;
}


.btn-outline-orange,
.btn-outline-orange:hover{
  color: $text-orange-light !important;
  border-color: $btn-color-orange !important;
}

.theme_orange_dark .btn-check:checked + .btn{
  background-color: $btn-color-orange !important;
  color: $text-orange !important;
}

.btn-color-orange-dark{
  background-color: $orange-dark !important;
  border: 1px solid $orange-dark !important;
  color: $text-white !important;
}

.btn-color-orange-dark:hover,
.btn-color-orange-dark:active{
  background-color: $btn-color-orange-dark !important;
}

.btn-chart-orange{
  background-color: $btn-color-orange;
  border-color: $btn-color-orange  !important;
}

.btn-chart-orange:hover,
.btn-chart-orange:active{
  background-color: $btn-color-orange-hover;
  border-color: $btn-color-orange-hover  !important;
}



.theme_table_lean_orange tbody tr td {
  background-color: $orange-normal;
  color: $text-orange !important;
  cursor: default;
}

.theme_table_lean_orange tbody tr td a {
  color: $text-orange !important;
  cursor: default;
}

.theme_table_orange tbody tr:nth-child(even) td {
  background-color: $orange-normal;
  color: $text-orange;
  border: solid 1px $orange-table-border;
  cursor: default;
}

.theme_table_orange tbody tr:nth-child(odd) td {
  background-color: $orange-light;
  color: $text-orange;
  border: solid 1px $orange-table-border;
  cursor: default;
}

.theme_table_orange  tr:nth-child(even) td {
  background-color: $orange-normal;
  color: $text-orange;
  border: solid 1px $orange-table-border;
  cursor: default;
}

.theme_table_orange tr:nth-child(odd) td {
  background-color: $orange-light;
  color: $text-orange;
  border: solid 1px $orange-table-border;
  cursor: default;
}

.theme_table_head_orange tr th {
  background-color: $orange-dark;
  border: solid 1px $btn-color-orange-hover;
  color: $text-orange-light;
  cursor: default;
}

.theme_table_head_orange tr th span:focus{
  color: $text-orange;
  cursor: default;
}


.theme_icon_orange{
  color: $text-orange;
}

.theme_border_orange{
  border-color: $text-orange !important;
}

.theme_set_border_orange{
  stroke: $orange-dark;

}


.theme_text_compagny_orange{
  stroke: $text-orange;
  fill: $text-orange;
  color: $text-orange;
}

.theme_compagny_stroke_orange .rd3t-link{
  color: $text-orange;
  stroke: $text-orange !important;
  background-color: $text-orange;
}

























/* Theme rouge */

$red-light: #9a211b;
$red-normal: #800801;
$red-dark: #640601;

$text-white:#fff ;
$text-red: #e8cdcc;
$text-red-light:#f3e6e5;
$text-red-red:#fa6874;
$text-red-yellow:#bb9c3f;
$text-red-light-cyan:#c3ebea;

$red-page-link :#e2b4b2;
$red-page-item: #d17a77;

$link-red : #d17a77;

$red-light-active: #800801 ;
$red-cyan: #37BFBB ;
$red-red: #fa6874;
$red-green: #3f9854;

$red-red-hover:#8f363f;

$btn-color-red:#c78480;
$btn-color-red-dark:#7d3734;
$btn-color-red-hover:#ca7772;
$btn-red-lean-hover: #ca7772;
$btn-red-green: #3a7948;
$btn-red-green-hover:#368247;
$btn-red-yellow-hover: #3c3c3c;
$btn-red-yellow: #caa843;

$btn-list-red:#e0837e;

$red-table-border:#d99692;

$red-calendar-cell-disabled: #e2837e;
$red-calendar-header : #d99692;






.theme_red_light{
  background-color: $red-light;
  color: $text-red;
  border:none;
}

.theme_red_light:hover{
  background-color: $red-light;
}

.theme_red_light:active{
  background-color: $red-light-active !important;
}


.theme_slide_red_light{
  background-color: $red-light;
  color: $text-red;
  border:none;
}

.theme_red_normal{
  background-color: $red-normal !important;
  color: $text-red !important;
  border:none;
}

.theme_red_normal .btn-close{
  background-color: $text-red;
  color: $text-red;
}

.theme_red_normal .rct-icon{
  color: $text-red;
}

.theme_red_normal .form-check-input:checked {
  color: $text-red !important;
  background-color: $red-cyan !important;
  border-color: $red-cyan !important;
}

.theme_red_normal .form-control,
.theme_red_normal .form-select,
.theme_red_normal .form-multi-select,
.theme_red_normal .form-multi-select-tag,
.theme_red_normal .form-multi-select-search,
.theme_red_normal .form-multi-select-options,
.theme_red_normal .form-control::placeholder,
.theme_red_normal .form-select::placeholder,
.theme_red_normal .form-multi-select::placeholder{
  color: $text-red !important;
  background-color: $red-dark !important;
  border-color: $red-normal !important;
}

.theme_red_normal .form-range{
  background-color: $red-normal !important;
  color: $text-red !important;
  border:none;
}

.theme_red_normal input{
  color: $text-red !important;
  background-color: $red-dark !important;
  border-color: $red-normal !important;
}

.theme_red_normal .form-control:disabled,

.theme_red_normal .form-select:disabled,
.theme_red_normal .form-multi-select:disabled,
.theme_red_normal .form-multi-select-tag:disabled,
.theme_red_normal .form-multi-select-search:disabled,
.theme_red_normal .form-multi-select-options:disabled,
.theme_red_normal .form-multi-select.disabled,
.theme_red_normal textarea:disabled::placeholder,
.theme_red_normal .form-control:disabled::placeholder
{
  color: $text-red !important;
  background-color: $red-light !important;
  border-color: $red-normal !important;
}

.theme_red_normal input:disabled{
  color: $text-red !important;
  background-color: $red-light !important;
  border-color: $red-normal !important;
}

.theme_red_normal .form-multi-select-dropdown{
  border-color: $red-light  !important;
}

.theme_red_normal .form-multi-select-option.form-multi-selected::before,
.theme_red_normal .form-multi-select-option.form-multi-select-option-with-checkbox::before{
  background-color: $red-dark !important;
  border-color: $red-dark  !important;
}

.theme_red_normal .calendar-header-cell-inner,
.theme_red_normal .calendar-cell-inner,
.theme_red_normal .input-group-text,
.theme_red_normal .dropdown-menu{
  color: $text-red !important;
  background-color: $red-dark !important;
  border-color: $red-normal !important;
}

.theme_red_normal .calendar-cell.disabled .calendar-cell-inner{
  color: $red-calendar-cell-disabled !important;
}

.theme_red_normal .calendar-cell-inner:active,
.theme_red_normal .calendar-cell-inner:hover{
  color: $red-dark !important;
  background-color: $red-light !important;
}

.theme_red_normal .calendar-nav ,
.theme_red_normal .calendar-nav .btn span,
.theme_red_normal .calendar-nav .btn .btn-transparent .calendar-nav-icon-double-next,
.theme_red_normal .picker-footer

{
  color: $red-normal !important;
  background-color: $text-red !important;
  border-color: $red-normal !important;
}

.theme_red_normal .picker-footer button{
  color: $text-red !important;
  background-color: $red-dark !important;
  border-color: $red-normal !important;
}


.theme_red_normal .form-multi-select-option{
  color: $text-red !important;
  background-color: $red-light !important;
  border-color: $red-normal !important;
  margin: 5px 0px;
}

.theme_red_normal .form-multi-select button{

  color: $text-red !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $red-dark !important; /* couleur de fond de l'input lorsqu'il est en focus ou actif */
  border-color: $red-dark  !important;
}


.theme_red_normal .pagination .page-item:disabled a,
.theme_red_normal .pagination .page-item a,
.theme_board_red_normal .pagination .page-item:disabled a,
.theme_board_red_normal .pagination .page-item a{
  background-color: $red-light !important;
  color: $text-red !important;
  border:1px solid $red-light;
}

.theme_red_normal .page-link,
.theme_board_red_normal .page-link{
  background-color: $red-page-link !important;
  color: $text-red !important;
  border:1px solid $red-page-link;
}


.theme_red_dark{
  background-color: $red-dark !important;
  color: $text-red;
  border:none;
}

.theme_red_dark .accordion-header .accordion-button{
  background-color: $red-light;
  color: $text-red;
  border:none;
  box-shadow: none;
}


.theme_red_dark .dropdown-item:hover{
  background-color: $red-normal;
  color: $text-red;
  cursor: pointer;

}

.theme_red_dark::placeholder{
  color: $text-red;
}

.theme_red_dark input:focus,
.theme_red_dark input:active {
  color: $text-red !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $red-dark !important;
}

.theme_red_dark:focus,
.theme_red_dark:active {
  color: $text-red !important; /* couleur du texte de l'input lorsqu'il est en focus ou actif */
  background-color: $red-dark !important; /* couleur de fond de l'input lorsqu'il est en focus ou actif */
}

.theme_navbar_red{
  background-color: $red-dark !important;
  color: $text-red ;
}

.theme_lean_red_dark{
  color: $text-red;
  background-color: $red-dark !important;
  border: $red-dark solid 1px;
}

.theme_lean_red_dark:hover{
  color: $text-red;
  background-color: $btn-red-lean-hover !important;
  border: $btn-red-lean-hover solid 1px;
}


.theme_lean_red_dark:active{
  color: $text-red;
  background-color: $btn-red-lean-hover !important;
  border: $btn-red-lean-hover solid 1px !important;
}

.theme_slideshow_red_dark{
  background-color: $red-dark !important;
  padding-top : 10px;
  color: $text-red;
  border:none;
}


.theme_slideshow_red_normal{
  background-color: $red-normal !important;
  padding-top : 10px;
  color: $text-red;
  border:none;
}

 .hori-timeline .events{
  border-top:none;
}

.theme_red_red{
  background-color: $red-red;
  border: 1px solid $red-red;
}

.theme_red_green{
  background-color: $red-green;
  border:none;
}


.theme_board_red_normal{
  background-color: $red-dark !important;
  color:$text-red;
  border-color: $red-dark !important;

}

.theme_board_red_normal input,
.theme_board_red_normal input::placeholder,
.theme_board_red_normal input:focus,
.theme_board_red_normal .form-select
{
  background-color: $red-normal !important;
  color:$text-red;
  border-color: $red-light !important;

}

.theme_board_red_light{
  background-color: $red-light;
  color:$text-red;
  border-color: $red-light !important;
}

.theme_board_red_dark{
  background-color: $red-dark !important;
  color:$text-red;
  border-color: $red-dark !important;
}


.theme_red_dark .page-item a,
.theme_red_normal .page-item a{
  background-color: $red-light !important;
  color:$text-red;
  border-color: $red-light !important;
}


.theme_red_dark .page-item span,
.theme_red_normal .page-item span{
  background-color: $red-page-item !important;
  color:$text-red;
  border-color: $red-page-item !important;
}



.theme_text_noddle_red{
  color: $text-red !important;
}


.theme_calendar_red_light,
.theme_calendar_red_light button
{
  background-color: $red-light !important;
  color: $text-red !important;
  border:none;
  box-shadow: none !important;
}

.theme_calendar_red_normal{
  background-color: $red-normal;
  color: $text-red;
  border:none;
}

.theme_calendar_red_dark{
  background-color: $red-dark;
  color: $text-red;
  border:none;
}

.theme_calendar_red_dark .fc-daygrid-day-number{
  color: $text-red;
}

.theme_calendar_red_dark .fc-button,
.theme_calendar_red_dark .form-check-input:checked,
.theme_calendar_red_dark .form-check-input::before
{
  background-color: $red-cyan !important;
  border-color: $red-cyan !important;
}

.theme_calendar_red_dark .fc-day-today{
  background-color: $red-normal !important;
}

.theme_calendar_red_dark .fc-col-header-cell,
.theme_calendar_red_dark .fc-col-header-cell a
{
  color: $text-red;
  background-color: $red-calendar-header !important;
  border-color: $red-calendar-header !important;
}

.theme_calendar_red_dark .fc-daygrid-day-frame:hover,
.theme_calendar_red_dark .fc-daygrid-day-frame:active{
  background-color: $red-light;
}

.theme_user_checkbox_red{
  background-color: $red-dark !important;
  border-color: $red-dark !important;
}


.theme_text_red{
  color: $text-red !important;
}

.theme_text_red:disabled{
  color: $text-red !important;
}

.theme_text_red:hover{
  color: $text-red-light;

}

.theme_text_red:active{
  color: $text-red;
}

.theme_text_red_red{
  color: $text-red-red !important;
}

.theme_text_green_red{
  color: $red-green !important;
}

.theme_text_yellow_red{
  color: $text-red-yellow !important;
}

.theme_text_cyan_red{
  color: $text-red-light-cyan !important;
}

.theme_home_text_red{
  color: $text-red;
}

.theme_text_light_red{
  color: $text-red-light !important;
}

.theme_link_red{
  color: $link-red !important;
}

.theme_link_red a{
  color: $text-red;
}

.theme_link_red span{
  color: $text-red;
}

.theme_text_badge_red{
  color: $text-red !important;
}






.bg-gradient-red {
  --bs-bg-opacity: 1;
  background-image: linear-gradient(90deg, $red-dark, $red-normal);
}








.btn-color-red{
  background-color: $btn-color-red !important;
  border: 1px solid $btn-color-red !important;
}

.btn-color-red:hover,
.btn-color-red:active{
  background-color: $btn-color-red-hover !important;
}

.btn-badge-open-red{
  background-color: $red-cyan;
  border: none;
}

.btn-badge-closed-red{
  background-color: $btn-color-red;
  border: none;
}

.btn-badge-archived-red{
  background-color: $btn-color-red;
  border: none;
}

.btn-badge-yellow-red,
.btn-badge-yellow-red:disabled
{
  background-color: $red-dark;
  border: 1px solid $red-dark;
}

.btn-badge-yellow-red:hover,
.btn-badge-yellow-red:active{
  background-color: $btn-red-yellow-hover !important;
  border: 1px solid $btn-red-yellow-hover;
}


.btn-color-red-red{
  background-color: $text-red-red;
  border: 1px solid $text-red-red;
}

.btn-color-red-red:hover,
.btn-color-red-red:active
{
  background-color: $red-red-hover !important;
  border: 1px solid $red-red-hover !important;
}

.btn-green-red,
.btn-green-red:disabled
{
  background-color: $btn-red-green !important;
  border: 1px solid $btn-red-green !important;
}

.btn-green-red:hover,
.btn-green-red:active{
  background-color: $btn-red-green-hover !important;
}

.btn-yellow-red,
.btn-yellow-red:disabled
{
  background-color: $text-red-yellow !important;
  border: none;
}

.btn-yellow-red:hover,
.btn-yellow-red:active{
  background-color: $btn-red-yellow !important ;
}

.btn-list-color-red .nav-link{
  background-color: $btn-color-red !important;
  border-radius: 5px !important;
  color: $text-red;
}

.btn-list-color-red .nav-link:hover{
  background-color: $btn-list-red !important;
}

.btn-board-red label,
.btn-board-red:hover label
{
  color: $text-red !important;
  border-color: $btn-color-red !important;
}

.btn-board-red .btn-check:checked + .btn{
  background-color: $btn-color-red !important;
}


.btn-outline-red,
.btn-outline-red:hover
{
  color: $text-red !important;
  border-color: $btn-color-red !important;
}

.theme_red_dark .btn-check:checked + .btn{
  background-color: $btn-color-red !important;
  color: $text-red-light !important;
}

.btn-color-red-red{
  background-color: $red-dark !important;
  border: 1px solid $red-dark !important;
  color: $text-white !important;
}

.btn-color-red-red:hover,
.btn-color-red-red:active{
  background-color: $btn-color-red-dark !important;
}

.btn-chart-red{
  background-color: $btn-color-red;
  border-color: $btn-color-red  !important;
}

.btn-chart-red:hover,
.btn-chart-red:active{
  background-color: $btn-color-red-hover;
  border-color: $btn-color-red-hover  !important;
}



.theme_table_lean_red tbody tr td {
  background-color: $red-normal;
  color: $text-red !important;
  cursor: default;
}

.theme_table_lean_red tbody tr td a {
  color: $text-red !important;
  cursor: default;
}

.theme_table_red tbody tr:nth-child(even) td {
  background-color: $red-normal;
  color: $text-red;
  border: solid 1px $red-table-border;
  cursor: default;
}

.theme_table_red tbody tr:nth-child(odd) td {
  background-color: $red-light;
  color: $text-red;
  border: solid 1px $red-table-border;
  cursor: default;
}

.theme_table_red  tr:nth-child(even) td {
  background-color: $red-normal;
  color: $text-red;
  border: solid 1px $red-table-border;
  cursor: default;
}

.theme_table_red tr:nth-child(odd) td {
  background-color: $red-light;
  color: $text-red;
  border: solid 1px $red-table-border;
  cursor: default;
}

.theme_table_head_red tr th {
  background-color: $red-dark;
  border: solid 1px $btn-color-red-hover;
  color: $text-red-light;
  cursor: default;
}

.theme_table_head_red tr th span:focus{
  color: $text-red;
  cursor: default;
}


.theme_icon_red{
  color: $text-red;
}

.theme_border_red{
  border-color: $text-red !important;
}

.theme_set_border_red{
  stroke: $red-dark;

}


.theme_text_compagny_red{
  stroke: $text-red;
  fill: $text-red;
  color: $text-red;
}

.theme_compagny_stroke_red .rd3t-link{
  color: $text-red;
  stroke: $text-red !important;
  background-color: $text-red;
}


// Style for the info / versioning tab in the account management -> settings page
.accountInfoAccordion {
	&__body {
		display: flex;
		flex-direction: column;
	}
}

// Style for the post-it comment section in the board view
.post-it {
  margin-left: auto;
	align-self: flex-end;
  background-color: #fdf489;
  height: 11rem;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  border-radius: .25rem;

  &__header {
    padding: 0.25rem 0.5rem;
    background-color: #efe063;
    flex-grow: 0;
  }

  &__input {
    background-color: rgba(0, 0, 0, 0.1);
    border: none;
    margin-inline: auto;
    border-radius: .25rem;
    max-width: calc(100% - 1rem);
    flex-grow: 2;
  }

  &__button-container {
    display: flex;
    justify-content: right;
    gap: 5px;
    padding: 5px;
    flex-grow: 0;
  }

  &__button-container button {
    border: 1px solid #ECDB46;
    padding: 1px 4px;
    border-radius: 5px;
    background-color: #efe063;

    &:hover {
      background-color: #dbcf5d;
    }
  }
}

.board-theme-title {
  display: block;
  width: 100%;
  padding-inline: 0.5rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.theme-chart-title {
  display: flex;
  gap: 1rem;
  flex-flow: row nowrap;

  &__text {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}